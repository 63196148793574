import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DevExtremeModule} from '../devextreme.module';
import {AdjustmentPricingGridComponent} from './adjustment-pricing-grid.component';
import {OptionsCommonModule} from '../options-common/options-common.module';
import {AgGridModule} from 'ag-grid-angular';
import {EtsSymbolPickerModule} from '../symbol-picker/symbol-picker.module';
import {LastPriceModule} from '../last-price/last-price.module';
import {AdjustmentPricingGridPanelComponent} from './panel/adjustment-pricing-grid-panel.component';
import {
    CashFlowStrategySettingsComponent
} from './settings-section/cash-flow-strategy-settings/cash-flow-strategy-settings.component';
import {AdjustmentGridSettingsSectionComponent} from './settings-section/adjustment-grid-settings-section.component';
import {
    AdjustmentGridGlobalSettingsComponent
} from './settings-section/global-settings/adjustment-grid-global-settings.component';
import {
    AdjustmentGridExpirationsSettingsComponent
} from './settings-section/expirations-settings/adjustment-grid-expirations-settings.component';
import {AdjustmentGridPositionsStateComponent} from './popup/positions/adjustment-grid-positions-state.component';
import {AdjustmentGridOrdersComponent} from './popup/orders/adjustment-grid-orders.component';
import {
    AdjustmentPricingGridPositionsSectionComponent
} from './positions-section/adjustment-pricing-grid-positions-section.component';
import {BeforeStatePopupDefaultsHeaderComponent} from './positions-section/before-state-defaults-header.component';
import {AdjustmentComparisonGridPanelComponent} from './panel/adjustment-comparison-grid-panel.component';
import {MarketTimeModule} from '../market-time/market-time.module';
import {ApgChecklistComponent} from './popup/checklist/apg-checklist.component';
import {AllowStylesPipe} from './popup/checklist/allow-styles.pipe';
import {AdjustmentSolutionPopupComponent} from './solution-popup/adjustment-solution-popup.component';
import {ApgAfterStatePreviewComponent} from './after-state-preview/apg-after-state-preview.component';
import {ApgPortfolioRefreshDialogComponent} from './portfolio-refresh-dialog/apg-portfolio-refresh-dialog.component';
import {SweetPriceTrackingService} from './services/sweet-price-tracking.service';
import {ApgTrackingDialogComponent} from './positions-section/tracking-dialog/apg-tracking-dialog.component';
import {DateTimePickerModule} from '../datetime-picker/datetime-picker.module';
import {TimezonePickerModule} from '../timezone-picker/timezone-picker.module';
import {
    ApgTrackingSyncDialogComponent
} from './positions-section/tracking-sync-dialog/apg-tracking-sync-dialog.component';
import {AssignableAdjustmentTemplatesService} from './settings-section/assignable-adjustment-templates.service';
import {EtsNgClassAsyncPipe} from '../common-services/ets-ngclass-async.pipe';
import {DynamicOffsetsButtonComponent} from './positions-section/dynamic-offsets/dynamic-offsets-button.component';
import {GenericConfirmationDialogModule} from '../generic-confirmation-dialog/generic-confirmation-dialog.module';
import {ApgContextMenuComponent} from "./context-menu/apg-context-menu.component";
import {VisibleOffsetsComponent} from "./visible-offsets/visible-offsets.component";
import {VisibleOffsetsSettingsService} from "./visible-offsets/visible-offsets-settings.service";
import {PriceboxMenuComponent} from "./pricebox-menu/pricebox-menu.component";
import {IsValidNumberPipe} from "../common-services/is-valid-number.pipe";
import {AfterStateDetalizationComponent} from "./after-state-detalization/after-state-detalization.component";
import {PriceboxCellComponent} from "./pricebox-cell/pricebox-cell.component";
import {StrategiesCellComponent} from "./strategies-cell/strategies-cell.component";
import {ZonesGridSettingsComponent} from "./zones-grid-settings/zones-grid-settings.component";
import {ZonesGridComponent} from "./zones-grid/zones-grid.component";
import {ZonesGridCellComponent} from "./zones-grid/zones-grid-cell/zones-grid-cell.component";
import {ZonesGridMenuComponent} from "./zones-grid-menu/zones-grid-menu.component";
import {VolatilityHeaderComponent} from "./volatility-header/volatility-header.component";
import {EtsCommonModule} from "../common-services/common.module";
import {ApgHedgesTotalComponent} from "./hedges-total/apg-hedges-total.component";
import {HedgingGridModule} from "../hedging-grid/hedging-grid.module";
import {OverrideAtmDialogModule} from "../override-atm-dialog/override-atm-dialog.module";
import {
    PauseNotificationsComponent
} from "./positions-section/tracking-dialog/pause-notifications/pause-notifications.component";

@NgModule({
    declarations: [
        AdjustmentPricingGridComponent,
        AdjustmentPricingGridPanelComponent,
        AdjustmentComparisonGridPanelComponent,

        AdjustmentGridSettingsSectionComponent,
        AdjustmentGridGlobalSettingsComponent,
        AdjustmentGridExpirationsSettingsComponent,
        CashFlowStrategySettingsComponent,
        AdjustmentGridPositionsStateComponent,
        AdjustmentGridOrdersComponent,
        AdjustmentPricingGridPositionsSectionComponent,
        BeforeStatePopupDefaultsHeaderComponent,
        ApgChecklistComponent,
        AllowStylesPipe,
        AdjustmentSolutionPopupComponent,
        ApgAfterStatePreviewComponent,
        ApgPortfolioRefreshDialogComponent,
        ApgTrackingDialogComponent,
        ApgTrackingSyncDialogComponent,
        DynamicOffsetsButtonComponent,
        ApgContextMenuComponent,
        VisibleOffsetsComponent,
        PriceboxMenuComponent,
        AfterStateDetalizationComponent,
        PriceboxCellComponent,
        StrategiesCellComponent,
        ZonesGridSettingsComponent,
        ZonesGridComponent,
        ZonesGridCellComponent,
        ZonesGridMenuComponent,
        VolatilityHeaderComponent,
        ApgHedgesTotalComponent,
        PauseNotificationsComponent
    ],

    imports: [
        EtsCommonModule,
        CommonModule,
        DevExtremeModule,
        OptionsCommonModule,
        EtsSymbolPickerModule,
        LastPriceModule,
        AgGridModule.withComponents([]),
        MarketTimeModule,
        DateTimePickerModule,
        TimezonePickerModule,
        GenericConfirmationDialogModule,
        HedgingGridModule,
        OverrideAtmDialogModule
    ],

    exports: [
        AdjustmentPricingGridComponent,
        AdjustmentPricingGridPanelComponent,
        AdjustmentComparisonGridPanelComponent,
        AdjustmentSolutionPopupComponent,
        CashFlowStrategySettingsComponent,
        AllowStylesPipe,
        IsValidNumberPipe,
        ApgHedgesTotalComponent
    ],

    providers: [
        SweetPriceTrackingService,
        AssignableAdjustmentTemplatesService,
        VisibleOffsetsSettingsService
    ]
})
export class AdjustmentPricingGridModule {
}
