// tslint:disable: no-string-literal

import {formatCurrency} from '@angular/common';
import * as shortid from 'shortid';
import {PanelModel} from 'projects/shared-components/workspace/panel.model';
import {IPanelComponent} from './panels/panel-component.interface';
import {TradingInstrument} from './trading-instruments/trading-instrument.class';
import {DateTime} from 'luxon';
import {EtsConstants} from './ets-constants.const';
import {QuoteDto} from './shell-communication/dtos/quote-dto.class';
import {ChangeDetectorRef} from '@angular/core';
import {ParametersControlBase} from './edit-strategy-dialog/parameters-control-base';
import {TIME_ZONE_FAMILIES} from './timezones/time-zones.const';
import * as Enumerable from 'linq';
import {OrderDto} from './shell-communication/dtos/order-dto.class';
import {
    CashFlowStrategyRole,
    GetAvailableBucketsReply,
    OptionExpirationDescriptor
} from './shell-communication/shell-dto-protocol';
import {LastQuoteCacheService} from './last-quote-cache.service';
import {OrderType} from './trading-model/order-type.enum';
import {StrategyModel} from './strategies/strategy-model';
import {SolutionOrderLegDto} from './adjustment-pricing-grid/model/SolutionOrderLegDto';
import {CashFlowStrategy} from './adjustment-control-panel/cash-flow-strategy';
import {AccessControlService} from './access-control-service.class';
import {isUndefined} from 'util';
import {ICashFlowAdjustmentSettingsTemplate} from "./adjustment-pricing-grid/model/ICashFlowAdjustmentSettingsTemplate";
import {SolutionPositionDto} from "./adjustment-pricing-grid/model/SolutionPositionDto";
import {AdjustmentPricingSettingsDto} from "./shell-communication/shell-operations-protocol";
import {parseOptionTicker} from "./options-common/options.model";


//
const ETS_KEY_VALUE_DELIMITER = '::=';

//
const ETS_KVP_DELIMITER = '(~)';

//
export const INDEX_SYMBOLS = ['XSP', 'VIX', 'SPX', 'SPXW'];

//
function isDefined(value: any): boolean {
    return typeof value !== 'undefined';
}

//
export function toNearestTickSize(value: number, tickSize: number): number {
    return (Math.round(value / tickSize) * tickSize) || value;
}

//
export function defaultCurrencyFormatter(value): string {
    return formatCurrency(value, 'en-US', '$', 'USD', '1.2-2');
}

//
export function clone(obj) {
    if (obj == null || typeof (obj) !== 'object') {
        return obj;
    }

    const temp = new obj.constructor();

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            temp[key] = clone(obj[key]);
        }
    }

    return temp;
}

//
export const MS_PER_DAY = 1000 * 60 * 60 * 24;

//
export function fromKVPString(kvpString: string): { [ix: string]: string } {
    if (!kvpString) {
        return {};
    }
    const lines = kvpString.split(ETS_KVP_DELIMITER);
    const dict = {};
    lines.forEach(line => {
        const parts = line.split(ETS_KEY_VALUE_DELIMITER);
        if (parts.length !== 2) {
            throw new Error(`Bad kvp string: ${kvpString}. Expected '${ETS_KEY_VALUE_DELIMITER}' separator`);
        }
        dict[parts[0]] = parts[1];
    });

    return dict;
}

//
export function toKVPString(object): string {
    if (!object) {
        return '';
    }

    // CONCERN: have got an assert fail in test, because of the one extra NewLine
    let kvpString = '';
    let cntr = 0;
    const keys = Object.keys(object);
    keys.forEach(key => {
        const value = object[key];
        kvpString += `${key}${ETS_KEY_VALUE_DELIMITER}${value}`;
        cntr++;
        if (cntr !== keys.length) {
            kvpString += ETS_KVP_DELIMITER;
        }
    });
    return kvpString;
}

//
export function isTruthy(value: any) {
    return isDefined(value) && !!value;
}

//
export function isArray(x) {
    return x && typeof x.length === 'number' && typeof x !== 'function';
}

//
export function makeLayoutTabId(layoutTabId: string = null) {
    layoutTabId = layoutTabId || shortid.generate();
    return layoutTabId;
}

//
export function makeWorkspaceId(layoutTabId: string, workspaceId: string = null): string {
    if (!layoutTabId) {
        throw new Error('Bad Layout Tab Id');
    }

    workspaceId = workspaceId || shortid.generate();

    return `${layoutTabId}${EtsConstants.storageKeys.separator}${workspaceId}`;
}

//
export function getPanelStateKey(panel: IPanelComponent | PanelModel) {
    return `${panel.workspaceId}${EtsConstants.storageKeys.separator}${panel.panelId}`;
}

//
export function isNullOrUndefined(value): boolean {
    return !isDefined(value) || value === null;
}

//
export function cloneObject(object) {
    const copy = {};
    const keys = Object.keys(object);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value = object[key];
        copy[key] = value;
    }
    return copy;
}

//
export function deepCloneObject(object) {
    return JSON.parse(JSON.stringify(object));
}

//
export function isInstrumentExpired(ti: TradingInstrument): boolean {
    if (!ti) {
        return true;
    }

    if (!ti.expirationDate) {
        return false;
    }

    const timezoneNow = DateTime.local().setZone(ti.exchangeTimeZoneId);
    const expirationDate = DateTime.fromJSDate(ti.expirationDate, {zone: ti.exchangeTimeZoneId});

    const isExpired = timezoneNow > expirationDate;

    return isExpired;
}

export function isOptionExpired(ticker: string): boolean {
    const optionTicker = parseOptionTicker(ticker);
    if (isVoid(optionTicker)) {
        return true;
    }

    const timezoneNow = DateTime.local().setZone('America/New_York');

    const expirationDate = DateTime.fromISO(optionTicker.expiration + 'T19:00:00',{zone: 'America/New_York'});

    const isExpired = timezoneNow > expirationDate;

    return isExpired;
}

//
export function isInstrumentAboutToExpire(ti: TradingInstrument): boolean {
    if (!ti || !ti.expirationDate) {
        return true;
    }

    const timezoneNow = DateTime.local().setZone(ti.exchangeTimeZoneId);
    const expirationDate = DateTime.fromJSDate(ti.expirationDate, {zone: ti.exchangeTimeZoneId});

    const diff = expirationDate.diff(timezoneNow, 'days');

    return diff.days <= 5;
}

//
export const DELIVERY_MONTHS_MAP = {
    F: 'Jan',
    G: 'Feb',
    H: 'Mar',
    J: 'Apr',
    K: 'May',
    M: 'Jun',
    N: 'Jul',
    Q: 'Aug',
    U: 'Sep',
    V: 'Oct',
    X: 'Nov',
    Z: 'Dec',
};

//
export function correctedDateAsUTC(date?: Date): Date {
    if (!date) {
        return null;
    }
    let corrected = DateTime.fromJSDate(date);
    corrected = corrected.setZone('UTC', {keepLocalTime: true});
    return corrected.toJSDate();
}

//
export function delay(ms): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
}

//
export interface TextValueMap<T> {
    text: string;
    value: T;
}

//
export function findHCF(numbers: number[]): number {

    numbers = numbers
        .filter(x => isValidNumber(x, true))
        .map(x => Math.abs(x));

    // Use spread syntax to get the minimum of the array
    const lowest = Math.min(...numbers);

    for (let factor = lowest; factor > 1; factor--) {

        let isCommonDivisor = true;

        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < numbers.length; j++) {

            if (numbers[j] % factor !== 0) {
                isCommonDivisor = false;
                break;
            }
        }

        if (isCommonDivisor) {

            if (factor.toString().indexOf('.') >= 0) {
                factor = 1;
            }

            return factor;
        }
    }

    return 1;
}

//

/*
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array)) {
            return l;
        }
    }
    return -1;
}

//
export function getShortUUID() {
    return shortid.generate();
}

//
export function isHedgingAlgo(algoId: string): boolean {

    let isHedging = false;

    switch (algoId) {
        case EtsConstants.algorithms.hedgeAlgoId:
        case EtsConstants.algorithms.freezeHedgeAlgoId:
        case EtsConstants.algorithms.dynamicHedgeAlgoId:
            isHedging = true;
            break;
        default:
            break;
    }

    return isHedging || isAdjustmentAlgo(algoId);
}

//
export function isVisibleInTradingSystems(strat: StrategyModel): boolean {
    return !isHedgingAlgo(strat.algoId)
        && !isAdjustmentAlgo(strat.algoId)
        && strat.algoId !== EtsConstants.algorithms.interestAlgoId
        && strat.algoId !== EtsConstants.algorithms.manualStrategyId
        && !strat.isArchived;
}

//
export function isAdjustmentAlgo(algoId: string): boolean {

    let isAdjustment = false;

    switch (algoId) {
        case EtsConstants.algorithms.adjustment.putSpreadAlgoId:
        case EtsConstants.algorithms.adjustment.longOptionAlgoId:
        case EtsConstants.algorithms.adjustment.shortOptionAlgoId:
        case EtsConstants.algorithms.adjustment.putDebitSpreadRollAlgoId:
            isAdjustment = true;
            break;
        default:
            break;
    }

    return isAdjustment;
}


//
export function findAtmStrikeIndex(strikes: number[], lastQuote: QuoteDto): number {

    const integralStrikes = strikes;// strikes.filter(x => Number.isInteger(x));

    let centerIx = Math.ceil(integralStrikes.length / 2 - 1);

    if (lastQuote) {

        // determine ATM strike


        const centerIx0 = integralStrikes.findIndex(x => x >= lastQuote.lastPx);
        const centerIx1 = centerIx0 - 1;
        const centerStrike0 = integralStrikes[centerIx0];
        const centerStrike1 = integralStrikes[centerIx1];
        const centerStrikeDiff0 = Math.abs(lastQuote.lastPx - centerStrike0);
        const centerStrikeDiff1 = Math.abs(lastQuote.lastPx - centerStrike1);
        const bestDiff = Math.min(centerStrikeDiff0, centerStrikeDiff1);

        if (bestDiff === centerStrikeDiff0) {
            centerIx = centerIx0;
        } else if (bestDiff === centerStrikeDiff1) {
            centerIx = centerIx1;
        } else {
            centerIx = centerIx0;
        }

        centerIx = strikes.indexOf(integralStrikes[centerIx]);

    }

    return centerIx;
}

//
export function DetectMethodChanges(params: { isAsync?: boolean, delay?: number, tag?: string } = {}): MethodDecorator {

    // tslint:disable-next-line: ban-types
    return (target: Function, key: string, descriptor: PropertyDescriptor) => {

        const originalMethod = descriptor.value;

        descriptor.value = function b(...args: any[]) {

            if (params.delay) {

                setTimeout(() => {

                    if (params.isAsync) {

                        return originalMethod.apply(this, args)
                            .then(() => {
                            })
                            // .catch((e) => console.error(e))
                            .finally(() => {

                                // tslint:disable-next-line: no-string-literal
                                const cd: ChangeDetectorRef = this['_changeDetector'];

                                if (cd) {
                                    cd.detectChanges();
                                }

                            });

                    } else {

                        try {

                            return originalMethod.apply(this, args);

                        } finally {

                            const cd: ChangeDetectorRef = this['_changeDetector'];

                            if (cd) {
                                cd.detectChanges();
                            } else {
                                console.error('No _changeDetector');
                            }
                        }
                    }
                }, params.delay);

            } else {

                if (params.isAsync) {

                    return originalMethod.apply(this, args)
                        .then(() => {
                        })
                        // .catch((e) => console.error(e))
                        .finally(() => {
                            const cd: ChangeDetectorRef = this['_changeDetector'];
                            if (cd) {
                                cd.detectChanges();
                            }
                        });

                } else {

                    try {

                        return originalMethod.apply(this, args);

                    } catch (e) {

                        console.error(e);

                    } finally {

                        const cd: ChangeDetectorRef = this['_changeDetector'];

                        if (cd) {
                            cd.detectChanges();
                        } else {
                            console.error('No _changeDetector');
                        }
                    }
                }
            }
        };

        return descriptor;
    };
}

//
export function DetectSetterChanges(config?: { debugTag?: string, delay?: number }) {

    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

        const original = descriptor.set;

        descriptor.set = function (value: any) {

            try {
                original.call(this, value);
            } catch (e) {
                console.error(e);
            } finally {

                const cd: ChangeDetectorRef = this['_changeDetector'];
                if (cd) {
                    if (config && config.delay) {
                        setTimeout(() => {
                            cd.detectChanges();
                        }, config.delay);
                    } else {

                        cd.detectChanges();
                    }

                }
            }

        };

        return descriptor;
    };
}

//
export function DetectParameterChanges(config?: { debugTag: string }) {

    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

        const original = descriptor.set;

        descriptor.set = function (this: ParametersControlBase<any>, value: any) {

            try {

                original.call(this, value);

            } catch (e) {

                console.error(e);

            } finally {

                this.hasChanges();

            }

        };

        return descriptor;
    };
}

//
export function wrapInPromise(action: () => void, timeout?: number): Promise<void> {
    return new Promise((res, rej) => {
        setTimeout(() => {
            action();
            res();
        }, timeout || 0);
    });
}

//
export interface DxValueChanged<T> {
    value?: T;
    previousValue?: T;
    event?: string;
    element?: HTMLElement;
}

//
export class ArrayWrapper<T extends unknown> {
    private _data: T[];

    constructor(public readonly sortBy: string, public readonly key: string) {
    }

    get data(): T[] {
        if (!this._data) {
            this._data = [];
        }
        return this._data;
    }

    setData(data: T[]) {
        this._data = this.sortData(data);
    }

    insert(item: T) {
        this._data.push(item);
        this.setData(this._data);
    }

    remove(id: string) {
        const ix = this._data.findIndex(d => d[this.key] === id);

        if (ix === -1) {
            return;
        }

        this._data.splice(ix, 1);

        this._data = this._data.slice();
    }

    clear() {
        this._data = [];
    }

    private sortData(data: T[]): T[] {

        return data.sort((a, b) => {

            if (isNullOrUndefined(b)) {
                return -1;
            }

            if (isNullOrUndefined(a)) {
                return 1;
            }

            const valA: string = a[this.sortBy];
            const valB: string = b[this.sortBy];

            return valA.localeCompare(valB);
        });
    }
}

//
export const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

//
export function groupTimezoneFamilies() {
    const tzFamiliesGroup = [
        {
            key: 'Favorites',
            items: []
        },
        {
            key: 'Others',
            items: []
        }
    ];

    const availableTimeZoneFamilies = TIME_ZONE_FAMILIES.slice();

    availableTimeZoneFamilies.forEach(tzf => {
        if (tzf.displayName === 'United States') {
            tzFamiliesGroup[0].items.push(tzf);
        } else {
            tzFamiliesGroup[1].items.push(tzf);
        }
    });

    return tzFamiliesGroup;
}

//
export function tickersMatch(tickerA: string, tickerB: string): boolean {
    if (isNullOrUndefined(tickerA)) {
        return false;
    }

    if (isNullOrUndefined(tickerB)) {
        return false;
    }

    if (tickerA === tickerB) {
        return true;
    }

    if (tickerA.startsWith('@') && tickerB.startsWith('@')) {

        if (tickerA.endsWith(' $')) {
            return tickerA.startsWith(tickerB);
        }

        if (tickerB.endsWith(' $')) {
            return tickerB.startsWith(tickerA);
        }
    }

    return false;
}

//
export function isCashSettledOptionTicker(ticker: string) {
    if (isNullOrUndefined(ticker)) {
        return;
    }

    let isCash = ticker.startsWith('@') && ticker.endsWith(' $');

    if (!isCash && ticker.startsWith('@')) {
        INDEX_SYMBOLS.forEach(x => {
            if (isCash) {
                return;
            }
            const symbol = `@${x} `;
            if (ticker.indexOf(symbol) >= 0) {
                isCash = true;
            }
        });
    }

    return isCash;
}

//
export function isCashSettledInstrument(ticker: string) {
    return isCashSettledOptionTicker(ticker)
        || INDEX_SYMBOLS.includes(ticker);
}

//
export function isCashSettledComboOrder(legsDescriptor: string) {
    if (legsDescriptor.indexOf(' $') > 0) {
        return true;
    }

    let isCash = false;

    INDEX_SYMBOLS.forEach(x => {
        if (isCash) {
            return;
        }
        const symbol = `@${x} `;
        if (legsDescriptor.indexOf(symbol) >= 0) {
            isCash = true;
        }
    });

    return isCash;
}

//
export function convertCashSettledTickerToNormal(cashTicker: string): string {

    if (!cashTicker.endsWith(' $')) {
        return cashTicker;
    }

    return cashTicker.substring(0, cashTicker.length - 2);
}

//
export function convertTickerToCashSettled(ticker: string): string {
    if (ticker.endsWith(' $')) {
        return ticker;
    }
    return ticker + ' $';
}

//
export function isFuturesTicker(ticker: string) {
    if (ticker.length <= 2 || ticker.length > 6) {
        return false;
    }

    if (ticker.startsWith('@')) {
        return false;
    }

    if (ticker.indexOf('.') >= 0) {
        return false;
    }

    if (ticker.indexOf('-') >= 0) {
        return false;
    }

    const strMonth = ticker.substring(ticker.length - 2, ticker.length - 1);
    const strYear = ticker.substring(ticker.length - 1, ticker.length);

    const mothCode = DELIVERY_MONTHS_MAP[strMonth];

    if (isNullOrUndefined(mothCode)) {
        return false;
    }

    if (isNaN(parseInt(strYear))) {
        return false;
    }

    return true;
}

//
export function makeMultiLegOrderCode(mlDescriptor: string): string {
    const legs: string[] = mlDescriptor.split('|');

    const legsSplitInParts: string[][] = legs.map(leg => leg.split(','));

    const parsedLegsData = legsSplitInParts.filter(legParts => legParts.length >= 3).map(legParts => {

        const displayName = legParts[2];
        let qty = legParts[1];

        if (!qty.startsWith('-')) {
            qty = '+' + qty;
        }

        const displayNameParts = displayName.split(' ');

        let optionType = null;
        let expiration = null;
        let strike = null;
        let underlying = null;

        if (displayNameParts.length > 1) {
            optionType = displayNameParts[1];
            strike = displayNameParts[2];
            expiration = displayNameParts[3];
        } else {
            underlying = displayNameParts[0];
        }

        return {underlying, optionType, strike, expiration, qty};

    }).sort(x => x.underlying);

    const legsInfoByExpiration = Enumerable.from(parsedLegsData)
        .groupBy(pld => pld.expiration)
        .reverse() // .Reverse() required to get underlyings first, they don't have expirations
        .select(gr => {
            const legCodes = gr.orderBy(x => x.strike)
                .select(x => {
                    const code = !isNullOrUndefined(x.underlying)
                        ? `${x.qty}U`
                        : `${x.qty}${x.optionType.toUpperCase()[0]}${x.strike}`;

                    return code;
                });

            const codifyedLegs = legCodes.toArray().join('/');

            let retval;

            if (!isNullOrUndefined(gr.key())) {
                const key = gr.key() + '';
                const shortExpiration = key.substring(0, key.length - 3);
                retval = ` [${shortExpiration} :: ${codifyedLegs}]`;
            } else {
                retval = ` [${codifyedLegs}]`;
            }

            return retval;

        });

    const mlOrderLegsCode = legsInfoByExpiration.toArray().join(',');

    return mlOrderLegsCode;
}

//
export function parseOrderLegs(order: OrderDto, buckets: GetAvailableBucketsReply, lastQuoteCache: LastQuoteCacheService) {
    const serializedLegs = order.multiLegDescriptor.split('|');

    if (serializedLegs.length === 0) {
        return [];
    }

    const legs = serializedLegs.map(sl => {
        const parts = sl.split(',');

        if (parts.length < 3) {
            return null;
        }

        lastQuoteCache.subscribeTicker(parts[0]);

        const rowData: any = {
            ticker: parts[0],
            side: parts[1][0] === '-' ? 'Sell' : 'Buy',
            qty: parseInt(parts[1]),
            tickerDisplayName: parts[2]
        };

        if (parts.length > 3) {
            const bucketsData = parts[3];
            const bucketsIds = bucketsData.split('~');

            if (bucketsIds.length > 0) {
                const portfolioId = bucketsIds[0];
                const comboId = bucketsIds[1];
                const comboGroupId = bucketsIds[2];

                if (portfolioId) {
                    const pfName = buckets.portfolios
                        .find(x => x.portfolioId === portfolioId)?.portfolioName;

                    rowData.portfolioName = pfName || 'N/A';
                }

                if (comboId) {
                    const comboName = buckets.combos
                        .find(x => x.comboId === comboId)?.comboName;

                    rowData.comboName = comboName || 'N/A';
                }

                if (comboGroupId) {
                    const comboGroupName = buckets.comboGroups
                        .find(x => x.comboGroupId === comboGroupId)?.comboGroupName;

                    rowData.comboGroupName = comboGroupName || 'N/A';
                }
            }
        }

        return rowData;

    })
        .filter(x => x !== null);

    order.legs = legs;
}

//
export function padTime(value: number): string {
    return ('0' + (value || 0)).slice(-2);
}

//
export function isMarketOrder(orderType: OrderType): boolean {
    switch (orderType) {
        case OrderType.Market:
        case OrderType.MarketOnOpen:
            return true;
        default:
            return false;
    }
}

//
export function isLimitOrder(orderType: OrderType): boolean {
    switch (orderType) {
        case OrderType.Limit:
        case OrderType.LimitOnOpen:
            return true;
        default:
            return false;
    }
}

//
export function militaryTimeToAmPm(time: string): string {
    if (isNullOrUndefined(time)) {
        return 'N/A';
    }

    const parts = time.split(':');

    if (parts.length !== 3) {
        return time;
    }

    const hours = parseInt(parts[0]);

    if (isNaN(hours)) {
        return time;
    }

    let ampmHours = hours;
    let ampm = 'AM';

    if (hours >= 12) {
        ampm = 'PM';
        if (hours > 12) {
            ampmHours = Math.abs(hours - 12);
        }
    }

    return `${ampmHours}:${parts[1]}:${parts[2]} ${ampm}`;
}

//
export function timespanToUserFriendly(timespan: string): string {
    if (isNullOrUndefined(timespan)) {
        return 'N/A';
    }

    const parts = timespan.split(':');

    if (parts.length !== 3) {
        return timespan;
    }

    return `${parts[0]} hrs. ${parts[1]} mins. ${parts[2]} secs.`;
}

//
export function daysToExpiration(expirationDate: string): any {

    if (!expirationDate) {
        return '?';
    }

    const sExpDate = expirationDate + 'T23:59:59';
    const estZone = {zone: 'America/New_York'};
    const expDate = DateTime.fromISO(sExpDate, estZone);
    const estNow = DateTime.fromObject(estZone);

    const diff = expDate.diff(estNow, 'days');
    let diffInDays = diff.get('days');

    diffInDays = Math.floor(diffInDays);

    return diffInDays;
}

//
export function getBucketRoleClass(item: { role: CashFlowStrategyRole }): string {

    if (item.role === 'ShortOption') {
        return 'colorcode-short-option';
    }

    if (item.role === 'SpreadLongLeg' || item.role === 'SpreadShortLeg') {
        return 'colorcode-spread';
    }

    if (item.role === 'SecondSpreadLongLeg' || item.role === 'SecondSpreadShortLeg') {
        return 'colorcode-second-spread';
    }

    if (item.role === 'ProtectiveOption') {
        return 'colorcode-protective-option';
    }

    if (item.role === 'SecondProtectiveOption') {
        return 'colorcode-protective-option';
    }

}

//
export function getQtyClass(position: any): any {
    if (position.qty > 0) {
        return 'long';
    } else if (position.qty < 0) {
        return 'short';
    }
    return undefined;
}

//
export function getActionClass(order: SolutionOrderLegDto) {
    if (order.action === 'Buy To Close' || order.action === 'Buy To Open') {
        return 'buy';
    }

    if (order.action === 'Sell To Close' || order.action === 'Sell To Open') {
        return 'sell';
    }

    return undefined;
}

//
export function getPriceClass(item: any): any {
    if (isVoid(item)) {
        return undefined;
    }

    if (item.price > 0) {
        return 'credit';
    } else if (item.price < 0) {
        return 'debit';
    }

    return undefined;
}

//
export function isVoid(value: any) {

    if (isNullOrUndefined(value)) {
        return true;
    }

    if (Array.isArray(value)) {
        return value.length === 0;
    }

    if (typeof value === 'string') {
        return value === '' || value === 'null';
    }

    if (typeof value === 'number') {
        return value === 0 || isNaN(value);
    }

    return !value;
}

//
export function isValidNumber(value: number, falseOnZero = false): boolean {

    if (isNullOrUndefined(value)) {
        return false;
    }

    if (isNaN(value)) {
        return false;
    }

    if (!isFinite(value)) {
        return false;
    }

    if(Math.abs(value) < Number.EPSILON) {
        value = 0;
    }

    if (value === 0) {
        if (falseOnZero) {
            return false;
        }
    }

    return true;
}

//
export interface GetNearestStrikeSpec {
    strikes: number[];
    desiredStrike: number;
    direction: 'up' | 'down';
    stopStrike?: number,
    stopStrikeExclusive?: boolean;
}

//
export function getNearestStrike(spec: GetNearestStrikeSpec): number {

    if (isVoid(spec)) {
        throw new Error('Empty spec provided');
    }

    if (isVoid(spec.strikes)) {
        throw new Error('No strikes listed')
    }

    if (isVoid(spec.desiredStrike)) {
        throw new Error('Desired strike not provided');
    }

    if (['up', 'down'].indexOf(spec.direction) < 0) {
        throw new Error('Unknown direction');
    }

    if (spec.direction === 'down') {
        if (spec.stopStrike) {
            if (spec.desiredStrike < spec.stopStrike) {
                throw new Error('Bad range for specified direction');
            }
        }
    } else if (spec.direction === 'up') {
        if (spec.stopStrike) {
            if (spec.desiredStrike > spec.stopStrike) {
                throw new Error('Bad range for specified direction');
            }
        }
    }

    if (spec.strikes.indexOf(spec.desiredStrike) >= 0) {
        return spec.desiredStrike;
    }

    const strikes = Enumerable.from(spec.strikes.slice());

    let targetStrike: number;

    let orderedStrikes: Enumerable.IEnumerable<number>;

    if (spec.direction === 'up') {

        orderedStrikes = strikes
            .orderBy(x => x)
            .where(x => x >= spec.desiredStrike);

        if (spec.stopStrike) {
            orderedStrikes = orderedStrikes.where(x => x <= spec.stopStrike);
        }

        targetStrike = orderedStrikes.firstOrDefault(x => x >= spec.desiredStrike);

        if (isVoid(targetStrike)) {
            targetStrike = orderedStrikes.lastOrDefault();
        }

    } else if (spec.direction === 'down') {

        orderedStrikes = strikes
            .orderByDescending(x => x)
            .where(x => x <= spec.desiredStrike);

        if (spec.stopStrike) {
            orderedStrikes = orderedStrikes
                .where(x => x >= spec.stopStrike);
        }

        targetStrike = orderedStrikes.firstOrDefault(x => x <= spec.desiredStrike);

        if (isVoid(targetStrike)) {
            targetStrike = orderedStrikes.lastOrDefault();
        }

    } else {

        throw new Error('Unsuppoted direction')

    }

    return targetStrike;

}

//
export type PickOpitmalStrikeSpec = {
    strikes: number[];
    desiredStrike: number;
    strategyDirection: 'up' | 'down';
    shiftDirection: 'up' | 'down';
    maxStrike?: number;
    minStrike?: number;
    role?: CashFlowStrategyRole;
}

//
export function pickOpitmalStrike(spec: PickOpitmalStrikeSpec): number {

    if (isVoid(spec)) {
        throw new Error('Empty spec provided');
    }

    if (isVoid(spec.strikes)) {
        throw new Error('No strikes listed')
    }

    if (isVoid(spec.desiredStrike)) {
        throw new Error('Desired strike not provided');
    }

    const directions = ['up', 'down'];

    if (directions.indexOf(spec.strategyDirection) < 0) {
        throw new Error('Unknown strategy direction');
    }

    if (directions.indexOf(spec.shiftDirection) < 0) {
        throw new Error('Unknown shift direction');
    }

    if (spec.strategyDirection === spec.shiftDirection) {

        if (isVoid(spec.maxStrike)) {

            if (spec.role !== 'ShortOption') {
                throw new Error('Max strike is requried');
            }

        }

        if (spec.strategyDirection === 'down') {

            if (spec.desiredStrike < spec.maxStrike) {
                throw new Error('Bad range for specified strategyDirection');
            }

        } else if (spec.strategyDirection === 'up') {

            if (spec.desiredStrike > spec.maxStrike) {
                throw new Error('Bad range for specified strategyDirection');
            }

        }
    } else {

        if (isVoid(spec.minStrike)) {
            if (spec.role !== 'ShortOption') {
                throw new Error('Min strike is requried');
            }
        }

    }

    const strategyDirectionSign = spec.strategyDirection === 'up' ? 1 : -1;
    const shiftDirectionSign = spec.shiftDirection === 'up' ? 1 : -1;

    const isSameDirection = strategyDirectionSign === shiftDirectionSign;

    let correctedDesiredStrike = spec.desiredStrike;

    if (!isSameDirection) {

        if (!isVoid(spec.minStrike)) {
            const minStrikeVsDesired = numberCompare(spec.minStrike, spec.desiredStrike) * shiftDirectionSign;

            if (minStrikeVsDesired > 0) {
                correctedDesiredStrike = spec.minStrike;
            }
        }
    }

    if (spec.strikes.indexOf(correctedDesiredStrike) >= 0) {
        return correctedDesiredStrike;
    }

    let foundStrike: number;

    const strikes = Enumerable.from(spec.strikes.slice());

    let orderedStrikes = strikes.orderBy(x => x * shiftDirectionSign);

    let slicedStrikes: Enumerable.IEnumerable<number>;

    if (!isSameDirection) {

        orderedStrikes = strikes
            .orderBy(x => x * shiftDirectionSign);

        slicedStrikes = orderedStrikes
            .where(s => numberCompare(s, spec.minStrike) * shiftDirectionSign >= 0);

        foundStrike = slicedStrikes
            .firstOrDefault(x => numberCompare(x, correctedDesiredStrike) * shiftDirectionSign >= 0);

        return foundStrike;
    }

    slicedStrikes = orderedStrikes
        .where(s => numberCompare(s, spec.maxStrike) * strategyDirectionSign <= 0);

    const desiredVsstop = numberCompare(correctedDesiredStrike, spec.maxStrike) * strategyDirectionSign;

    if (desiredVsstop == 1) {

        foundStrike = slicedStrikes.lastOrDefault();

    } else {

        // going towards stop
        const rangeFromDesiredToStop = slicedStrikes.where(s => numberCompare(s, correctedDesiredStrike) * strategyDirectionSign >= 0);

        const firstAvailableStrikeInRage = rangeFromDesiredToStop.firstOrDefault();

        if (!isVoid(firstAvailableStrikeInRage)) {

            foundStrike = firstAvailableStrikeInRage;

        } else {

            // going away from stop
            foundStrike = slicedStrikes.reverse().firstOrDefault();

        }
    }

    return foundStrike;

}

//
export function isReversedCashFlowOrder(strategy: CashFlowStrategy): boolean {
    switch (strategy) {
        case 'Calls':
        case 'Reversed Hedged Portfolio':
            return true;
    }

    return false;
}

//
export function numberCompare(x: number, y: number): number {
    if (isVoid(x) && isVoid(y)) {
        return 0;
    }

    if (isVoid(x)) {
        return -1;
    }

    if (isVoid(y)) {
        return 1;
    }

    const diff = x - y;

    return diff === 0 ? 0 : Math.sign(diff);
}

//
export function getNearestExpiration(
    daysToExpiration: number,
    expirations: OptionExpirationDescriptor[]
) {

    const spreadExpirationIndex = expirations
        .findIndex(exp => exp.daysToExpiration >= (daysToExpiration || 0));

    let desiredExpiration: OptionExpirationDescriptor;

    if (spreadExpirationIndex >= 0) {
        desiredExpiration = expirations[spreadExpirationIndex];
    }

    return desiredExpiration;
}

//
export function getExpirationXBusinessDaysAfterDate(
    businessDays: number,
    date: string,
    expirations: OptionExpirationDescriptor[]
) {

    let dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd');

    let count = 0;
    while (true) {

        if (count >= businessDays) {
            break;
        }

        if (count > 90) {
            break;
        }

        dateTime = dateTime.plus({days: 1});

        if (dateTime.weekday <= 5) {
            count++;
        }
    }

    const targetDate = dateTime.toFormat('yyyy-MM-dd');

    const expiration =  Enumerable.from(expirations)
        .orderBy(exp => exp.optionExpirationDate)
        .firstOrDefault(exp => exp.optionExpirationDate >= targetDate);

    return expiration;
}


//
export function getCashFlowStrategySecureElementId(strategy: CashFlowStrategy): string | undefined {
    let id: string;

    if (strategy === 'Hedged Portfolio') {
        id = EtsConstants.algorithms.adjustment.cashFlow.hedgedPortfolioId;
    } else if (strategy === 'Reversed Hedged Portfolio') {
        id = EtsConstants.algorithms.adjustment.cashFlow.reversedHedgedPortfolioId;
    } else if (strategy === 'Calls') {
        id = EtsConstants.algorithms.adjustment.cashFlow.callsId;
    } else if (strategy === 'Puts') {
        id = EtsConstants.algorithms.adjustment.cashFlow.putsId;
    } else if (strategy === 'Calls & Puts') {
        id = EtsConstants.algorithms.adjustment.cashFlow.callsAndPutsId;
    }

    return id;
}

//
export function pickExpirationSmart(parameters: {
                                        targetStrike: number,
                                        strikeStart: number,
                                        strikeRange: number,
                                        strikeReverseDirection: boolean,
                                        expirationRangeStart: number,
                                        expirationRangeEnd: number,
                                        defaultDaysToExpiration: number,
                                        expirations: OptionExpirationDescriptor[],
                                    }
): OptionExpirationDescriptor {

    if (parameters.expirations.length == 0) {
        return null;
    }

    const strikeStart = parameters.strikeStart
    const strikeRange = parameters.strikeRange;

    const strikeReverseDirection = parameters.strikeReverseDirection;

    const expirationRangeStart = parameters.expirationRangeStart;
    const expirationRangeEnd = parameters.expirationRangeEnd;
    const defaultDaysToExpiration = parameters.defaultDaysToExpiration;

    const expirations = parameters.expirations;

    const filteredExpirations = expirations
        .filter(x => x.daysToExpiration >= expirationRangeStart)
        .filter(x => x.daysToExpiration <= expirationRangeEnd);

    if (filteredExpirations.length === 0) {
        return getNearestExpiration(defaultDaysToExpiration, expirations);
    }

    const multiplier = strikeReverseDirection ? 1 : -1;

    const strikeEnd = strikeStart + ((strikeRange * 1.5) * multiplier);

    const scoredExpirations = filteredExpirations.map(x => {

        const filteredStrikes = x.strikes
            .filter(strike => {
                const compareResult = numberCompare(strike, strikeStart) * multiplier;
                return compareResult >= 0;
            })
            .filter(strike => {
                const compareResult = numberCompare(strikeEnd, strike) * multiplier;
                return compareResult >= 0;
            });

        let score = filteredStrikes.length;

        if (x.strikes.indexOf(parameters.targetStrike) >= 0) {
            score = Math.ceil(score * 1.2);
        }

        return {
            count: score,
            expiration: x
        }
    });

    const best = Enumerable.from(scoredExpirations)
        .groupBy(x => x.count)
        .orderByDescending(x => x.key())
        .firstOrDefault();

    if (isVoid(best)) {
        return null;
    }

    if (best.count() === 1) {
        return best.first().expiration;
    }

    const expirationsWithDiff = best.select(x => {
        const diff = Math.abs(x.expiration.daysToExpiration - defaultDaysToExpiration);
        return {
            diff,
            expiration: x.expiration
        };
    });

    const desiredExpiration = expirationsWithDiff
        .orderBy(x => x.diff)
        .first()
        .expiration;

    return desiredExpiration;
}

//
export function getAvailableCashFlowStrategies(aclService: AccessControlService): CashFlowStrategy[] {
    const strats: CashFlowStrategy[] = [];

    if (aclService.isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.hedgedPortfolioId)) {
        strats.push('Hedged Portfolio');
    }

    if (aclService.isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.reversedHedgedPortfolioId)) {
        strats.push('Reversed Hedged Portfolio');
    }

    if (aclService.isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.callsId)) {
        strats.push('Calls');
    }

    if (aclService.isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.putsId)) {
        strats.push('Puts');
    }

    if (aclService.isSecureElementAvailable(EtsConstants.algorithms.adjustment.cashFlow.callsAndPutsId)) {
        strats.push('Calls & Puts');
    }

    return strats;
}

export function except<T>(array: T[], ...values: T[]) {
    values.forEach(v => {
        const ix = array.indexOf(v);
        if (ix >= 0) {
            array.splice(ix, 1);
        }
    });
}

export const DaysOfWeekMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
};

export const EasternStandardTimezone = 'America/New_York';

export function makeGuiFriendlyExpirationDate(expiration: string): string {
    const dt = DateTime.fromFormat(expiration, 'yyyy-MM-dd');
    const formatted = dt.toFormat('dd-MMM-yy');
    return formatted;
}

export function makeGuiFriendlyExpirationDateDte(expiration: string): string {
    let dt: DateTime;

    const friendlyDate = /\d{2}-[a-zA-z]{3}-\d{2}/gm;

    if (friendlyDate.test(expiration)) {
        dt = DateTime.fromFormat(expiration, 'dd-MMM-yy');
    } else {
        dt = DateTime.fromFormat(expiration, 'yyyy-MM-dd');
    }

    if (isVoid(dt)) {
        return '';
    }

    const date = dt.toFormat('dd-MMM-yy');
    const diff = daysToExpiration(dt.toFormat('yyyy-MM-dd'));

    const result = `${date} (${diff}d)`;

    return result;
}

export function makeFullExpirationDate(expiration: string): string {

    let dt: DateTime;

    const friendlyDate = /\d{2}-[a-zA-z]{3}-\d{2}/gm;

    if (friendlyDate.test(expiration)) {
        dt = DateTime.fromFormat(expiration, 'dd-MMM-yy');
    } else {
        dt = DateTime.fromFormat(expiration, 'yyyy-MM-dd');
    }

    if (isVoid(dt)) {
        return '';
    }

    const date = dt.toFormat('EEE dd-MMM-yy');
    const diff = daysToExpiration(dt.toFormat('yyyy-MM-dd'));

    const result = `${date} (${diff}d)`;

    return result;
}

//
export function getValueOrNull(v) {
    if (isUndefined(v)) {
        return null;
    }
    return v;
}

//
export function shortNameCodeForStrategy(strategy: CashFlowStrategy): string {
    switch (strategy) {
        case 'Calls':
            return 'C';
        case 'Calls & Puts':
            return 'C&P';
        case 'Hedged Portfolio':
            return 'HP';
        case 'Puts':
            return 'P';
        case 'Reversed Hedged Portfolio':
            return 'rHP';
        default:
            return 'N/A';
    }
}

//
export function arraysEqual(self, array) {
    // if the other array is a falsy value, return
    if (!array) {
        return false;
    }

    if (!self) {
        return false;
    }

    // if the argument is the same array, we can be sure the contents are same as well
    if (array === this) {
        return true;
    }

    // compare lengths - can save a lot of time
    if (self.length != array.length)
        return false;

    for (var i = 0, l = self.length; i < l; i++) {
        // Check if we have nested arrays
        if (self[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!self[i].equals(array[i]))
                return false;
        } else if (self[i] != array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}

export function areTemplateSettingsValuesEqual(a: any, b: any): boolean {

    const aIsVoid = isVoid(a) || !isValidNumber(a, true);

    const bIsVoid = isVoid(b) || !isValidNumber(b, true);

    if (aIsVoid && bIsVoid) {
        return true;
    }

    return a === b;
}


export const DefaultTemplateId = '----';


export function isDefaultTemplate(tpl: ICashFlowAdjustmentSettingsTemplate | string): boolean {
    if (!tpl) {
        return false;
    }

    if (typeof tpl === 'string') {
        return tpl.startsWith(DefaultTemplateId);
    }

    return !isVoid(tpl.templateId) && tpl.templateId.startsWith(DefaultTemplateId);
}

export function getCashFlowRoles(): CashFlowStrategyRole[] {
    const roles: CashFlowStrategyRole[] = [
        'ShortOption',
        'SpreadLongLeg',
        'SpreadShortLeg',
        'SecondSpreadLongLeg',
        'SecondSpreadShortLeg',
        'ProtectiveOption',
        'SecondProtectiveOption'
    ];
    return roles;
}

const RoleWeights: Record<CashFlowStrategyRole, number> = {
    ShortOption: 0,
    SpreadLongLeg: 1,
    SpreadShortLeg: 2,
    SecondSpreadLongLeg: 3,
    SecondSpreadShortLeg: 4,
    ProtectiveOption: 5,
    SecondProtectiveOption: 6,
    Asset: 7
}

export function sortByCashFlowRole(data: { role: CashFlowStrategyRole, strategy: CashFlowStrategy }[]) {

    if (data.length === 0) {
        return;
    }

    const item = data[0];
    const strategy = item.strategy;

    const isReverse = isReversedCashFlowOrder(strategy);

    data.sort((a, b) => {
        let res = RoleWeights[a.role] - RoleWeights[b.role];
        if (isReverse) {
            res *= -1;
        }
        return res;
    });
}

export function getChangingPositionsForZeroAdjustmentCase(beforeState: SolutionPositionDto[], afterState: SolutionPositionDto[]) {
    const roles: CashFlowStrategyRole[] = getCashFlowRoles();

    const pairs = roles.map(role => {

        const before = beforeState.find(x => x.role === role);
        const after = afterState.find(x => x.role === role);

        return {before, after};
    });

    const changingPositions = pairs.flatMap(pair => {

        const isVoidBefore = isVoid(pair.before) || isVoid(pair.before.ticker);
        const isVoidAfter = isVoid(pair.after) || isVoid(pair.after.ticker);


        if (isVoidBefore && isVoidAfter) {
            return undefined;
        }

        if (isVoidBefore) {
            return {
                qty: pair.after.qty,
                price: pair.after.price,
                role: pair.after.role,
            };
        }

        if (isVoidAfter) {
            return {
                qty: pair.before.qty * -1,
                price: pair.before.price,
                role: pair.before.role
            };
        }

        if (pair.after.ticker !== pair.before.ticker) {
            return [
                {
                    qty: pair.after.qty, price:
                    pair.after.price,
                    role: pair.after.role
                },
                {
                    qty: pair.before.qty * -1,
                    price: pair.before.price,
                    role: pair.before.role
                }
            ];
        }

        const qty = pair.after.qty - pair.before.qty;

        if (Math.abs(qty) === 0) {
            return undefined;
        }

        if (qty > 0) {
            return {
                qty: qty,
                price: pair.after.price,
                role: pair.after.role,
            }
        }

        if (qty < 0) {
            return {
                qty: qty,
                price: pair.before.price,
                role: pair.before.role,
            };
        }

    }).filter(x => !isVoid(x));

    return changingPositions;
}

export function balanceStatePositions(beforeState: SolutionPositionDto[], afterState: SolutionPositionDto[]) {

    if (beforeState.length === afterState.length) {
        return;
    }

    const missingInAfter = beforeState
        .filter(bs => afterState.findIndex(as => as.role === bs.role) < 0);

    const missingInBefore = afterState
        .filter(as => beforeState.findIndex(bs => bs.role === as.role) < 0);

    missingInAfter.forEach(x => {
        const dto: SolutionPositionDto = {
            role: x.role,
            strategy: x.strategy
        } as any;
        afterState.push(dto);
    });

    missingInBefore.forEach(x => {
        const dto: SolutionPositionDto = {
            role: x.role,
            strategy: x.strategy
        } as any;
        beforeState.push(dto);
    });

    sortByCashFlowRole(beforeState);
    sortByCashFlowRole(afterState);
}


export function roundToStep(number, increment) {
    return Math.round(number / increment) * increment;
}

export function removeFromArray(array: Array<any>, value: any) {
    if (isVoid(array)) {
        return;
    }

    const number = array.indexOf(value);
    if (number === -1) {
        return;
    }
    array.splice(number, 1);
}

export function removeExtraAdjustmentSettingsForComparison(s: AdjustmentPricingSettingsDto) {
    delete s.zonesGridAdjustment;
    delete s.zonesGridRange;
    delete s.zonesGridRangeDown;
    delete s.zonesGridRangeStep;
    delete s.theoreticalPriceMode;
    delete s.theoreticalPriceIv;
    delete s.theoreticalPriceIvList;

    function cutTicker(x: any): string {
        const ix = x.ticker.indexOf('!');
        if (ix < 0) {
            return;
        }
        x.ticker = x.ticker.substring(0, ix - 1);
    }

    s.beforeState.forEach(x => cutTicker(x));
}


export function calculateLightness(hexcolor: string) {

    if (isVoid(hexcolor)) {
        return 150;
    }

    if (hexcolor.startsWith('#')) {
        hexcolor = hexcolor.substr(1);
    }

    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return yiq;
}


export function isApgPortfolioSetting(key: string): boolean {
    const isCashFlowSetting = key.indexOf('apg.saved-positions') >= 0
        || key.indexOf('apg.last-used-template') >= 0;
    return isCashFlowSetting;
}

export function array_move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
}

export async function checkClipboardPermissions() : Promise<boolean> {
    // @ts-ignore
    const writePerm = await navigator.permissions.query({ name: 'clipboard-write' });
    const writeAllowed = writePerm.state === 'granted' || writePerm.state === 'prompt';
    return writeAllowed;
}
