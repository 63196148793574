import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DetectMethodChanges, findHCF, getShortUUID, isVoid} from "../utils";
import {ApgPortfolio} from "../adjustment-pricing-grid/model/ApgPortfolio";
import {HgPositionsSectionComponent} from "./positions-section/hg-positions-section.component";
import {HgSettingsSectionComponent} from "./settings-section/hg-settings-section.component";
import {CalculateCashFlowHedges, CalculateCashFlowHedgesReply} from "../shell-communication/shell-operations-protocol";
import {HedgePositionGroupsBySide} from "./data-model/hedge-position-groups-by-side";
import * as Enumerable from "linq";
import {HedgePositionGroup} from "./data-model/hedge-position-group";
import {ShellClientService} from "../shell-communication/shell-client.service";
import {HgPriceboxSectionComponent} from "./pricebox-section/hg-pricebox-section.component";
import {HedgeStateTransaction} from "./data-model/hedge-state-transaction";
import {LastQuoteCacheService} from "../last-quote-cache.service";
import {QuoteDto} from "../shell-communication/dtos/quote-dto.class";
import {MessageBusService} from "../message-bus.service";
import {HgStrategiesSectionComponent} from "./strategies-section/hg-strategies-section.component";
import {HgZonesGridSectionComponent} from "./zones-grid-section/hg-zones-grid-section.component";
import {ToastrService} from "ngx-toastr";
import {PositionsStateService} from "../adjustment-pricing-grid/services/positions-before-state.service";
import {ApgDataService} from "../adjustment-pricing-grid/services/apg-data.service";

@Component({
    selector: 'ets-hedging-grid',
    templateUrl: 'hedging-grid.component.html',
    styleUrls: [
        './hedging-grid.component.scss',
        './hedging-grid-common-styles.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HedgingGridComponent implements OnInit, AfterViewInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _shellService: ShellClientService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _messageBus: MessageBusService,
        private readonly _toastr: ToastrService,
        private readonly _apgDataService: ApgDataService,
        private readonly _apgPositionsStateService: PositionsStateService,
    ) {
    }

    private _isZonesGridLinked: boolean;

    private _loadingClientsCount = 0;

    private _quoteIx: { [ix: string]: { multiplier: number, data: { price: number } }[] } = {};

    isLoading: boolean;

    positionsSectionCollapsed: boolean;

    settingsSectionCollapsed: boolean;

    strategiesSectionCollapsed: boolean;

    priceboxSectionCollapsed: boolean;

    zonesGridSectionCollapsed: boolean;

    @ViewChild(HgPositionsSectionComponent, {static: false})
    positionsSectionCmp: HgPositionsSectionComponent;

    @ViewChild(HgSettingsSectionComponent, {static: false})
    settingsSectionCmp: HgSettingsSectionComponent;

    @ViewChild(HgStrategiesSectionComponent, {static: false})
    strategiesSectionCmp: HgStrategiesSectionComponent;

    @ViewChild(HgPriceboxSectionComponent, {static: false})
    priceboxSectionCmp: HgPriceboxSectionComponent;

    @ViewChild(HgZonesGridSectionComponent, {static: false})
    zonesGridSectionCmp: HgZonesGridSectionComponent;

    showHedgePadIconsInHeader = true;

    portfolioDefaultQty?: number

    ngOnInit() {
        this.strategiesSectionCollapsed = true;
        this.priceboxSectionCollapsed = true;
        this.zonesGridSectionCollapsed = false;

        this._messageBus
            .of<QuoteDto[]>('QuoteDto')
            .subscribe(x => this.onQuotes(x.payload));

        this._messageBus
            .of<{ isLoading: boolean }>('Hg.Loading')
            .subscribe(x => {
                const isLoading = x.payload.isLoading;
                if (isLoading) {
                    this._loadingClientsCount++;
                } else {
                    this._loadingClientsCount--;
                }
                this.isLoading = this._loadingClientsCount > 0;
                this._changeDetector.detectChanges();
            });
    }

    @DetectMethodChanges()
    ngAfterViewInit() {
        this.checkIfSettingsSectionIsShortVersion();
    }

    @DetectMethodChanges()
    toggleSectionCollapsed(section: 'positions' | 'settings' | 'strategies' | 'pricebox' | 'zones-grd') {
        switch (section) {
            case "positions":
                this.positionsSectionCollapsed = !this.positionsSectionCollapsed;
                break;
            case "settings":
                this.settingsSectionCollapsed = !this.settingsSectionCollapsed;
                break;
            case "strategies":
                this.strategiesSectionCollapsed = !this.strategiesSectionCollapsed;
                break;
            case "pricebox":
                this.priceboxSectionCollapsed = !this.priceboxSectionCollapsed;
                break;
            case "zones-grd":
                this.zonesGridSectionCollapsed = !this.zonesGridSectionCollapsed;
                break;
        }
        this.checkIfSettingsSectionIsShortVersion();
    }

    private checkIfSettingsSectionIsShortVersion() {
        const anyPricingOpen = !this.priceboxSectionCollapsed || !this.strategiesSectionCollapsed ||
            !this.zonesGridSectionCollapsed;

        if (anyPricingOpen) {
            this.settingsSectionCmp.isShortVersion = true;
        } else {
            this.settingsSectionCmp.isShortVersion = false;
        }

        this.showHedgePadIconsInHeader = this.settingsSectionCmp.isShortVersion;
    }

    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(args: ApgPortfolio) {

        this.isLoading = true;

        this._changeDetector.detectChanges();

        try {
            this.portfolioDefaultQty = await this.getDefaultQtyForPortfolio(args);
            await this.settingsSectionCmp?.onPortfolioSelected(args);
            this.strategiesSectionCmp?.onPortfolioSelected(args);
            this.priceboxSectionCmp?.onPortfolioSelected(args);
            this.zonesGridSectionCmp?.onPortfolioSelected(args);
        } finally {
            this._messageBus.publish({
                topic: 'Hg.PortfolioSelected',
                payload: {}
            });
            this.isLoading = false;
        }
    }

    @DetectMethodChanges({isAsync: true})
    async onReviewChangesClicked(byAfterState?: boolean) {

        const currentPositions =
            (this.positionsSectionCmp.hedgePositionsCmp.hedgePositions || []).slice();

        const hedgePositionsValid = currentPositions.every(x => x.isValid());

        if (!hedgePositionsValid) {
            this._toastr.error('Fix Hedge Positions\' Configuration To Review Changes');
            return;
        }

        const desiredPositions = (this.settingsSectionCmp.positions || [])
            .filter(x => !x.removed)
            .sort((a, b) => b.strike - a.strike)
            .slice();

        const desiredPositionsValid = desiredPositions.every(x => x.isValid());

        if (!desiredPositionsValid) {
            this._toastr.error('Fix Desired Positions\' Configuration To Review Changes')
            return;
        }


        //
        // Calls
        //
        const currentCalls = currentPositions
            .filter(x => x.type === 'Call')
            .map(x => x.asDto());

        const currentCallGroups = Enumerable.from(currentCalls)
            .groupBy(x => x.groupId)
            .toArray()
            .map(x => {
                const grp: HedgePositionGroup = {
                    groupId: x.key(),
                    label: x.first().label,
                    color: x.first().color,
                    side: 'Calls',
                    positions: x.toArray()
                };

                return grp;
            });

        const desiredCalls = desiredPositions
            .filter(x => x.type === 'Call')
            .map(x => x.asDto());

        const desiredCallGroups = Enumerable.from(desiredCalls)
            .groupBy(x => x.groupId)
            .toArray()
            .map(x => {
                const grp: HedgePositionGroup = {
                    groupId: x.key(),
                    label: x.first().label,
                    color: x.first().color,
                    side: 'Calls',
                    positions: x.toArray()
                };

                return grp;
            })

        const c: HedgePositionGroupsBySide = {
            side: 'Calls',
            currentState: currentCallGroups,
            desiredState: desiredCallGroups
        };

        if (this.settingsSectionCmp.priceToOpen) {
            c.currentState = [];
        }

        //
        // Puts
        //
        const currentPuts = currentPositions
            .filter(x => x.type === 'Put')
            .map(x => x.asDto());

        const currentPutGroups = Enumerable.from(currentPuts)
            .groupBy(x => x.groupId)
            .toArray()
            .map(x => {
                const grp: HedgePositionGroup = {
                    groupId: x.key(),
                    label: x.first().label,
                    color: x.first().color,
                    side: 'Puts',
                    positions: x.toArray()
                };

                return grp;
            });

        const desiredPuts = desiredPositions
            .filter(x => x.type === 'Put')
            .map(x => x.asDto());

        const desiredPutGroups = Enumerable.from(desiredPuts)
            .groupBy(x => x.groupId)
            .toArray()
            .map(x => {
                const grp: HedgePositionGroup = {
                    groupId: x.key(),
                    label: x.first().label,
                    color: x.first().color,
                    side: 'Puts',
                    positions: x.toArray()
                };

                return grp;
            })

        const p: HedgePositionGroupsBySide = {
            side: 'Puts',
            currentState: currentPutGroups,
            desiredState: desiredPutGroups
        };

        if (this.settingsSectionCmp.priceToOpen) {
            p.currentState = [];
        }

        const sides = [];

        if (!isVoid(currentCallGroups) || !isVoid(desiredCallGroups)) {
            sides.push(c);
        }

        if (!isVoid(currentPutGroups) || !isVoid(desiredPutGroups)) {
            sides.push(p);
        }

        if (isVoid(sides)) {
            this._toastr
                .error('Current configuration make no sense. ' +
                    'Please provide either current state or/and desired state of the hedge');
            return;
        }


        const qry = new CalculateCashFlowHedges(
            getShortUUID(),
            false,
            null,
            sides
        );

        this.isLoading = true;

        this._changeDetector.detectChanges();

        try {

            const reply = await this._shellService
                .processQuery<CalculateCashFlowHedgesReply>(qry);

            this.subscribeQuotes(reply.hedgeStateTransactions);

            this.strategiesSectionCmp.setData(reply.hedgeStateTransactions);

            this.priceboxSectionCmp.setData(reply.hedgeStateTransactions);

            const state = JSON.stringify(desiredPositions.map(x => x.asDto()));
            this.priceboxSectionCmp.setReviewedState(state)

            const portfolioPositions = this.positionsSectionCmp.portfolioPositionsCmp.portfolioPositions;

            this.zonesGridSectionCmp.setData(reply.hedgeStateTransactions, portfolioPositions);

            if (this._isZonesGridLinked) {
                await this.onZonesGridLoadDataClicked(true);
            } else if (byAfterState) {
                this.zonesGridSectionCmp.lastReviewedState = this.priceboxSectionCmp.lastReviewedState;
            }

        } finally {

            this.isLoading = false;

        }

        this._messageBus.publishAsync({
            topic: 'Hg.AfterReviewChanges',
            payload: {}
        });
    }

    private subscribeQuotes(hedgeStateTransactions: HedgeStateTransaction[]) {

        hedgeStateTransactions
            .forEach(hst => {

                hst.positionTransactions.forEach(x => {

                    if (!isVoid(x.afterState)) {

                        x.afterState.positions.forEach(apos => {

                            let container = this._quoteIx[apos.ticker];

                            if (isVoid(container)) {
                                container = [];
                                this._quoteIx[apos.ticker] = container;
                            }

                            const data = {
                                data: apos,
                                multiplier: x.noChanges ? Math.sign(apos.qty) : Math.sign(apos.qty) * -1,
                            } as any;

                            container.push(data);
                        });
                    }

                    if (!isVoid(x.beforeState)) {

                        x.beforeState.positions.forEach(bpos => {

                            let container = this._quoteIx[bpos.ticker];

                            if (isVoid(container)) {
                                container = [];
                                this._quoteIx[bpos.ticker] = container;
                            }

                            const data = {
                                data: bpos,
                                multiplier: Math.sign(bpos.qty),
                            } as any;

                            container.push(data);
                        });
                    }
                });


                hst.orderTransactions.forEach(x => {

                    if (!isVoid(x.orders)) {

                        const hcf =
                            findHCF(x.orders.map(x => Math.abs(x.qty)));

                        x.orders.forEach(order => {

                            let container = this._quoteIx[order.ticker];

                            if (isVoid(container)) {
                                container = [];
                                this._quoteIx[order.ticker] = container;
                            }

                            const data = {
                                data: order,
                                multiplier: Math.sign(order.qty) * -1,
                                hcf
                            } as any;

                            container.push(data);
                        });
                    }

                    if (!isVoid(x.balancedOrders)) {

                        x.balancedOrders.forEach(bo => {
                            const hcf =
                                findHCF(bo.map(x => Math.abs(x.qty)));

                            bo.forEach(order => {

                                let container = this._quoteIx[order.ticker];

                                if (isVoid(container)) {
                                    container = [];
                                    this._quoteIx[order.ticker] = container;
                                }

                                const data = {
                                    data: order,
                                    multiplier: Math.sign(order.qty) * -1,
                                    hcf
                                } as any;

                                container.push(data);
                            });
                        });
                    }

                });
            });

        const totalTickers = Object.keys(this._quoteIx);

        const cachedQuotes = this._lastQuoteCache.subscribeTickers(totalTickers);

        if (isVoid(cachedQuotes)) {
            return;
        }

        const quoteDtos = Object.values(cachedQuotes);

        this.onQuotes(quoteDtos);
    }

    private onQuotes(quoteDtos: QuoteDto[]) {
        quoteDtos.forEach(quote => {
            const container = this._quoteIx[quote.ticker];

            if (!isVoid(container)) {
                container.forEach(x => {
                    x.data.price = quote.mid * x.multiplier;
                });
            }

            this.settingsSectionCmp?.positions?.forEach(p => {
                const ticker = p.getTicker();
                if (isVoid(ticker)) {
                    return;
                }
                if (ticker !== quote.ticker) {
                    return;
                }
                p.price = quote.mid;
            });

        });

        this._messageBus.publishAsync({
            topic: 'Hg.RefreshQuotes',
            payload: {}
        });
    }

    onZonesGridLinkStateChanged(state: boolean) {
        this._isZonesGridLinked = state;
    }

    onZonesGridClearDataRequest() {
        this.zonesGridSectionCmp.clearData();
    }

    @DetectMethodChanges({isAsync: true})
    async onZonesGridLoadDataClicked(silently?: boolean) {
        this.isLoading = true;
        try {

            await this.zonesGridSectionCmp.loadDataClicked(silently);

            this.zonesGridSectionCmp.lastReviewedState = this.priceboxSectionCmp.lastReviewedState;

            this._messageBus.publishAsync({
                topic: 'Hg.AfterReviewChanges',
                payload: {}
            });

        } catch (e) {
            if (!isVoid(e) && !isVoid(e.message)) {
                this._toastr.error(e.message);
            }
        } finally {
            this.isLoading = false;
        }
    }

    @DetectMethodChanges()
    onExpandHedgePadRequest() {
        this.settingsSectionCollapsed = false;

        this.strategiesSectionCollapsed = this.priceboxSectionCollapsed =
            this.zonesGridSectionCollapsed = true;

        this.checkIfSettingsSectionIsShortVersion();
    }

    isZonesGridDifferent(): boolean {

        if (isVoid(this.priceboxSectionCmp)) {
            return false;
        }

        if (isVoid(this.zonesGridSectionCmp)) {
            return false;
        }

        const settingsState = this.priceboxSectionCmp.lastReviewedState;

        const zonesState = this.zonesGridSectionCmp.lastReviewedState;

        if (isVoid(zonesState)) {
            return false;
        }

        if (isVoid(settingsState)) {
            return !isVoid(zonesState);
        }

        return settingsState !== zonesState;
    }

    isZonesGridOutdated(): boolean {
        return this.zonesGridSectionCmp?.isAtmOutdated();
    }

    canLoadZonesGrid(): boolean {
        return !isVoid(this.priceboxSectionCmp?.lastReviewedState);
    }

    showZonesGridMenu() {
        this.zonesGridSectionCmp?.showMenu();
    }

    private async getDefaultQtyForPortfolio(args: ApgPortfolio) : Promise<number> {
        const underlyingOfPortfolio = await this._apgDataService.getUnderlyingOfPortfolio(args);

        const lut = await this._apgDataService.getLastUsedTemplateOfPortfolio(args);

        const serviceConfig = {
            orientation: undefined,
            userId: args.userId,
            userName: args.userName
        };

        this._apgPositionsStateService.configure(serviceConfig);

        const dp = this._apgPositionsStateService.getDefaultsProvider(
            args.id,
            underlyingOfPortfolio,
            lut.strategy === 'Calls & Puts' ? 'double' : 'single'
        );

        return dp?.first?.orderQty;
    }

}