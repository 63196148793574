import { DateTime } from 'luxon';

const IGNORE_WORDS = [
   'timezone',
   'timestostayotr',
   'timeframe',
   'ctltimes',
   'samsmaxtimestoincrease',
   'otrtimestostayotr',
   'otrmaxtimesdoubleotrbetonloss',
   'alterotrtimestostayotr',
   'charttimeframe',
   'timestampszone',
   'exchangetimezoneid',
   'timeofday',
   'optionexpirationdate',
   'optionexpirationdisplaydate',
   'timezoneid',
   'converttomarkettime',
   'ctmtimezone',
   'ctmtimestoreplace',
   'ctmactiontime',
   'ctmactiontimemode',
   'sellactiontimemode',
   'sellactiontime',
   'sellactiontimezone',
   'rolltimezone',
   'rolltimemode',
   'rolltime',
   'expirationDate',
   'adjustmenttime',
   'adjustmenttimeMode',
   'adjustmenttimezone',
   'converttime',
   'converttimezone',
   'reversetimedirection',
   'timestoreplace',
   'chargetime',
   'chargetimezone',
   'actiontime',
   'actiontimemode',
   'starttime',
   'endtime'
];

export function customJsonParser(key: string, value: any) {
   if (!value) {
      return value;
   }

   let parsed: any;
   const lowerCaseKey = key.toLowerCase();
   const hastriggerWords = lowerCaseKey.includes('time') || lowerCaseKey.includes('date');
   const shouldIgnore = IGNORE_WORDS.includes(lowerCaseKey);
   if (hastriggerWords && !shouldIgnore) {
      parsed = DateTime.fromISO(value, { zone: 'UTC' }).toJSDate();
      if (Object.prototype.toString.call(parsed) === '[object Date]') {
         if (isNaN(parsed)) { // d.getTime() or d.valueOf() will also work
           parsed = value;
         }
       } else {
         parsed = value;
       }
   } else {
      parsed = value;
   }
   return parsed;
}
