import {Injectable} from '@angular/core';
import {ClipboardService} from '../../clipboard.service';
import {MarketSide} from '../../trading-model/market-side.enum';
import {
    CashFlowAdjustmentSpec,
    ComboHighlightedItem,
    ComboHighlightedUIMessage,
    MultiLegOrderDataUIMessage
} from '../../ui-messages/ui-messages';
import {checkClipboardPermissions, findHCF, isValidNumber, isVoid} from '../../utils';
import {SolutionOrderLegDto} from '../model/SolutionOrderLegDto';
import {DateTime} from 'luxon';
import {parseOptionTicker} from '../../options-common/options.model';
import {ServiceConfiguration} from './ServiceConfiguration';
import {SettingsStorageService} from 'projects/shared-components/settings-storage-service.service';
import {CashFlowAdjustment} from '../model/CashFlowAdjustment';

export type OrderToCopy = 'Main' | 'Linked #1' | 'Linked #2' | 'Linked #3' | 'All';

export type CopyToDestination = 'ETS' | 'TOS Desktop' | 'TOS Web';

const LastUsedDestinationStorageKey = 'apg.last-used-destination';
const ComparisonLastUsedDestinationStorageKeyLeft = 'cpg.last-used-destination.left';
const ComparisonLastUsedDestinationStorageKeyRight = 'cpg.last-used-destination.right';


@Injectable()
export class CopyOrdersToService {
    constructor(
        private _clipboardService: ClipboardService,
        private _settingsStorageService: SettingsStorageService
    ) {
    }

    private _serviceConfig: ServiceConfiguration;

    //
    copyToETS(
        pricing: CashFlowAdjustment,
        orderToCopy: OrderToCopy = 'All',
        qty = 1,
        spec: CashFlowAdjustmentSpec
    ): boolean {

        let result = true;

        try {

            let main: ComboHighlightedUIMessage;
            if (orderToCopy === 'All' || orderToCopy === 'Main') {

                let hcf = 1;
                let orderQty = 1;

                if (pricing.isPriceToOpen) {
                    hcf = findHCF(pricing.mainLegs.map(x => Math.abs(x.qty)));
                }

                const mainLegs = pricing.mainLegs.map(x => {

                    let side: MarketSide = MarketSide.Flat;

                    if (x.action.indexOf('Buy') >= 0) {
                        side = MarketSide.Buy;
                    } else if (x.action.indexOf('Sell') >= 0) {
                        side = MarketSide.Sell;
                    }

                    const item: ComboHighlightedItem = {
                        ticker: x.ticker,
                        underlying: x.underlying,
                        side: side,
                        itemType: null,
                        netPosition: x.qty / hcf
                    } as any;

                    if (pricing.isPriceToOpen) {
                        item.netPosition *= qty;
                    }

                    return item;
                });

                main = {
                    orderParams: {
                        orderQty: orderQty
                    },
                    items: mainLegs,
                    cashFlowSpecs: spec
                };
            }

            let linked: ComboHighlightedUIMessage;
            if (orderToCopy === 'All' || orderToCopy === 'Linked #1') {

                let hcf = 1;
                let orderQty = 1;

                if (pricing.isPriceToOpen) {
                    hcf = findHCF(pricing.linkedLegs.map(x => Math.abs(x.qty)));
                }

                const linkedLegs = pricing.linkedLegs.map(x => {

                    let side: MarketSide = MarketSide.Flat;

                    if (x.action.indexOf('Buy') >= 0) {
                        side = MarketSide.Buy;
                    } else if (x.action.indexOf('Sell') >= 0) {
                        side = MarketSide.Sell;
                    }

                    const item: ComboHighlightedItem = {
                        ticker: x.ticker,
                        underlying: x.underlying,
                        side: side,
                        itemType: null,
                        netPosition: x.qty / hcf
                    } as any;

                    if (pricing.isPriceToOpen) {
                        item.netPosition *= qty;
                    }

                    return item;
                });

                linked = {
                    orderParams: {
                        orderQty: orderQty
                    },
                    items: linkedLegs,
                    cashFlowSpecs: spec
                };
            }

            let secondLinked: ComboHighlightedUIMessage;
            if (orderToCopy === 'All' || orderToCopy === 'Linked #2') {

                let hcf = 1;
                let orderQty = 1;

                if (pricing.isPriceToOpen) {
                    hcf = findHCF(pricing.secondLinkedLegs.map(x => Math.abs(x.qty)));
                }

                const secondLinkedLegs = pricing.secondLinkedLegs.map(x => {

                    let side: MarketSide = MarketSide.Flat;

                    if (x.action.indexOf('Buy') >= 0) {
                        side = MarketSide.Buy;
                    } else if (x.action.indexOf('Sell') >= 0) {
                        side = MarketSide.Sell;
                    }

                    const item: ComboHighlightedItem = {
                        ticker: x.ticker,
                        underlying: x.underlying,
                        side: side,
                        itemType: null,
                        netPosition: x.qty / hcf
                    } as any;

                    if (pricing.isPriceToOpen) {
                        item.netPosition *= qty;
                    }

                    return item;
                });

                secondLinked = {
                    orderParams: {
                        orderQty: orderQty
                    },
                    items: secondLinkedLegs,
                    cashFlowSpecs: spec
                };
            }

            let thirdLinked: ComboHighlightedUIMessage;
            if (orderToCopy === 'All' || orderToCopy === 'Linked #3') {

                let hcf = 1;
                let orderQty = pricing.isPriceToOpen ? qty : 1;

                if (pricing.isPriceToOpen) {
                    hcf = findHCF(pricing.thirdLinkedLegs.map(x => Math.abs(x.qty)));
                }

                const thirdLinkedLegs = pricing.thirdLinkedLegs.map(x => {

                    let side: MarketSide = MarketSide.Flat;

                    if (x.action.indexOf('Buy') >= 0) {
                        side = MarketSide.Buy;
                    } else if (x.action.indexOf('Sell') >= 0) {
                        side = MarketSide.Sell;
                    }

                    const item: ComboHighlightedItem = {
                        ticker: x.ticker,
                        underlying: x.underlying,
                        side: side,
                        itemType: null,
                        netPosition: x.qty / hcf
                    } as any;

                    if (pricing.isPriceToOpen) {
                        item.netPosition *= qty;
                    }

                    return item;
                });

                thirdLinked = {
                    orderParams: {
                        orderQty: orderQty
                    },
                    items: thirdLinkedLegs,
                    cashFlowSpecs: spec
                };
            }

            let orderCount = 0;
            if (!isVoid(main)) orderCount++;
            if (!isVoid(linked)) orderCount++;
            if (!isVoid(secondLinked)) orderCount++;

            if (orderCount > 1) {

                const msg: MultiLegOrderDataUIMessage = {
                    main,
                    linked,
                    secondLinked,
                    thirdLinked,
                    linkType: 'OTO',
                    cashFlowSpec: spec
                }

                this._clipboardService.put('multi-combo', msg);

            } else {

                if (!isVoid(main)) {
                    this._clipboardService.put('combo', main);
                } else if (!isVoid(linked)) {
                    this._clipboardService.put('combo', linked);
                } else if (!isVoid(secondLinked)) {
                    this._clipboardService.put('combo', secondLinked);
                } else if (!isVoid(thirdLinked)) {
                    this._clipboardService.put('combo', thirdLinked);
                }
            }

        } catch (e) {
            console.error(e);
            result = false;
        }

        return result;
    }

    //
    async copyToTOSDesktop(
        pricing: CashFlowAdjustment,
        orderToCopy: OrderToCopy = 'All',
        qty = 1
    ): Promise<boolean> {

        // BUY +10 1/1/-2 CUSTOM SPY 100 (Weeklys) 3 APR 23/3 APR 23/3 APR 23 409/411/410 CALL/CALL/PUT @.11 LMT

        const lines = [];

        if (!isVoid(pricing.mainLegs)) {
            const mainLine = this.makeTosDesktopOrderLine(pricing.mainLegs, qty, pricing.isPriceToOpen);

            if (isVoid(mainLine)) {
                return false;
            }

            if (orderToCopy === 'All' || orderToCopy === 'Main') {
                lines.push(mainLine);
            }
        }

        if (!isVoid(pricing.linkedLegs)) {
            let linkedLine = this.makeTosDesktopOrderLine(pricing.linkedLegs, qty,
                pricing.isPriceToOpen);

            if (isVoid(linkedLine)) {
                return false;
            }

            if (orderToCopy === 'All' || orderToCopy === 'Linked #1') {

                if (orderToCopy === 'All') {
                    linkedLine += ' TRG BY';
                }

                lines.push(linkedLine);
            }
        }

        if (!isVoid(pricing.secondLinkedLegs)) {

            let secondLinkedLine = this.makeTosDesktopOrderLine(pricing.secondLinkedLegs, qty,
                pricing.isPriceToOpen);

            if (isVoid(secondLinkedLine)) {
                return false;
            }

            if (orderToCopy === 'All' || orderToCopy === 'Linked #2') {

                if (orderToCopy === 'All') {
                    secondLinkedLine += ' TRG BY';
                }

                lines.push(secondLinkedLine);
            }

        }

        if (!isVoid(pricing.thirdLinkedLegs)) {

            let thirdLinkedLine = this.makeTosDesktopOrderLine(pricing.thirdLinkedLegs, qty,
                pricing.isPriceToOpen);

            if (isVoid(thirdLinkedLine)) {
                return false;
            }

            if (orderToCopy === 'All' || orderToCopy === 'Linked #3') {

                if (orderToCopy === 'All') {
                    thirdLinkedLine += ' TRG BY';
                }

                lines.push(thirdLinkedLine);
            }

        }

        const text = lines.join('\n');

        const result = await checkClipboardPermissions();

        if (result) {
            await navigator.clipboard.writeText(text);
        } else {
            throw Error('Could not copy to clipboard. Please check browser permissions');
        }

        return true;
    }


    //
    async copyToTOSWeb(
        pricing: CashFlowAdjustment,
        orderToCopy: OrderToCopy = 'Main',
        qty = 1
    ): Promise<boolean> {

        let legs: SolutionOrderLegDto[];

        if (orderToCopy === 'Main') {
            legs = pricing.mainLegs;
        } else if (orderToCopy === 'Linked #1') {
            legs = pricing.linkedLegs;
        } else if (orderToCopy === 'Linked #2') {
            legs = pricing.secondLinkedLegs;
        } else if (orderToCopy === 'Linked #3') {
            legs = pricing.thirdLinkedLegs;
        }

        if (isVoid(legs)) {
            return false;
        }

        const symbol = `symbol=${pricing.underlying}`;

        const tosLeg = legs.map((leg, ix) => {
            const tosLegNo = `leg${ix + 1}`;
            const ot = parseOptionTicker(leg.ticker);
            const expiration = DateTime.fromFormat(ot.expiration, 'yyyy-MM-dd').toFormat('yyMMdd');
            const tosTicker = `.${ot.expirationTicker.substring(1)}${expiration}${ot.type[0].toUpperCase()}${ot.strike}`;
            const actionSign = leg.action.indexOf('Buy') >= 0 ? 1 : -1;
            const fullLegDescriptor = `${tosLegNo}=${qty * actionSign},${tosTicker}`;

            return fullLegDescriptor;
        });

        const oType = 'orderType=LIMIT';
        const oPrice = 'limitPrice=';
        const oTif = 'orderTif=GTC';

        const host = 'https://trade.thinkorswim.com/trade';

        const request = `${host}?${symbol}&${tosLeg.join('&')}&${oType}&${oPrice}&${oTif}`;

        await navigator.clipboard.writeText(request);

        return true;
    }

    //
    configure(cfg: ServiceConfiguration) {
        this._serviceConfig = cfg;
    }

    //
    async saveLastUsedDestination(destination: CopyToDestination) {
        console.assert(!isVoid(this._serviceConfig));

        let key = this.getStorageKey();

        this._settingsStorageService
            .setItem(key, destination, this._serviceConfig.userId);
    }

    //
    getLastUsedDestination(): CopyToDestination {
        console.assert(!isVoid(this._serviceConfig));

        let key = this.getStorageKey();

        const dest = this._settingsStorageService
            .getItem<CopyToDestination>(key, this._serviceConfig.userId);

        return dest;
    }

    //
    private getStorageKey() {
        let key = LastUsedDestinationStorageKey;

        if (this._serviceConfig.orientation === 'left') {
            key = ComparisonLastUsedDestinationStorageKeyLeft;
        } else if (this._serviceConfig.orientation === 'right') {
            key = ComparisonLastUsedDestinationStorageKeyRight;
        }

        // if (this._serviceConfig.userId) {
        //     key += `${EtsConstants.storageKeys.userSeparator}${this._serviceConfig.userId}`;
        // }

        return key;
    }

    //
    private makeTosDesktopOrderLine(legs: SolutionOrderLegDto[], qty: number, isPriceToOpen: boolean) {

        if (legs.length === 0) {
            return undefined;
        }

        let hcf = 1;
        let orderQty = isPriceToOpen ? qty : 1;

        if (isPriceToOpen) {
            hcf = findHCF(legs.map(x => Math.abs(x.qty)));
        }

        const transformedLegs = legs
            .map(x => {
                const qty = x.qty / hcf;
                const date = DateTime.fromFormat(x.expiration, 'dd-MMM-yy').toFormat('dd MMM yy').toUpperCase();
                const strike = x.strike;
                const type = x.type.toUpperCase();
                const price = x.price;

                return {
                    qty,
                    date,
                    strike,
                    type,
                    price
                };
            });

        const underlying = legs[0].underlying;

        const quantities = transformedLegs.map(x => x.qty).join('/');
        const dates = transformedLegs.map(x => x.date).join('/');
        const strikes = transformedLegs.map(x => x.strike).join('/');
        const types = transformedLegs.map(x => x.type).join('/');
        const comboPrice = transformedLegs.reduce((p, c) => p + c.price, 0) * -1;

        if (!isValidNumber(comboPrice)) {
            return undefined;
        }


        let line: string;
        if (underlying === 'SPX') {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 (Weeklys) ${dates} ${strikes} ${types} @ LMT GTC`;
        } else {
            line = `BUY ${orderQty} ${quantities} CUSTOM ${underlying} 100 ${dates} ${strikes} ${types} @ LMT GTC`;
        }

        return line;

    }

}
