import {ProtocolQuery} from '../service-model/protocol-query.interface';
import {SHELL_PROTOCOL_OPERATIONS_NS} from './operations/shell-protocol-namespace.const';
import {ProtocolCommand} from '../service-model/protocol-command.interface';
import {PositionClass} from './dtos/aggregated-position-dto.class';
import {
    RolloverDescriptor,
    CustomExitOrderDescriptor,
    ExpirationItem,
    LedgerRecord,
    FutureTimeSettings,
    ConvertToMarketSettings,
    BucketAttributes,
    CashFlowTrackingRecordDto,
    AdjsutmentTemplateToClientsMap,
    ChecklistStepDto,
    GetDebitSpreadSectionDataModelReply,
    GetGlobalSettingsSectionDataModelReply,
    GetProtectiveOptionSectionDataModelReply,
    GetShortOptionSectionDataModelReply,
    InterestParameters,
    ApgDefaultSettingsDto, TrackingTemplate, UserSettingDto
} from './shell-dto-protocol';
import {AccountOverrideOptions} from '../sync-override-dialog/sync-override-dialog-types';
import {OrderLinkType, OrderType} from '../multi-trade-pad/multi-trade-pad.model';
import {TimeInForce} from '../trading-model/time-in-force.enum';
import {MarketSide} from '../trading-model/market-side.enum';
import {BucketType} from '../portfolios/portfolios.model';
import {LedgerSpec} from 'projects/webtrader/src/app/ledger/ledger.service';
import {PositionFlags} from './dtos/position-dto.class';
import {OrdersAndTradesLinkedContext} from '../manual-trading/manual-trading.model';
import {BeforePositionDto} from '../adjustment-pricing-grid/model/BeforePositionDto';
import {CashFlowStrategy} from '../adjustment-control-panel/cash-flow-strategy';
import {CashFlowAdjustmentSpec} from '../ui-messages/ui-messages';
import {
    IExpirationSmartModeSettings
} from '../app-settings/model/ExpirationSmartModeSettings';
import {CashFlowAdjustmentLogicalId} from '../adjustment-control-panel/CashFlowAdjustmentId';
import {AutomationCpMode} from '../automation-cp/model/AutomationCpMode';
import {
    SweetPriceSettings
} from '../adjustment-pricing-grid/positions-section/tracking-dialog/SweetPriceTrackingSettings';
import {ApgPortfolio} from '../adjustment-pricing-grid/model/ApgPortfolio';
import {ResourceButton} from "../resource-editor/resource-button.interface";
import {HedgePositionGroupsBySide} from "../hedging-grid/data-model/hedge-position-groups-by-side";
import {HedgeStateTransaction} from "../hedging-grid/data-model/hedge-state-transaction";
import {HedgePositionGroup} from "../hedging-grid/data-model/hedge-position-group";
import {CashFlowPortfoliosService} from "../adjustment-pricing-grid/services/cashflow-portfolios.service";
import {ajaxPut} from "rxjs/internal-compatibility";
import {HedgePosition} from "../hedging-grid/data-model/hedge-position";
import {CashFlowAdjustment} from "../adjustment-pricing-grid/model/CashFlowAdjustment";

import {PricingGridStrategyDescriptor} from "../options-pricing-grid/model/pricing-grid-strategy.descriptor";

export class GetPositionSizingRecords implements ProtocolQuery {
    constructor(public strategyId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPositionSizingRecords`;
    }
}

export class GetStrategySessionHistory implements ProtocolQuery {
    constructor(
        public strategyId: string,
        public startDate?: Date,
        public endDate?: Date) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategySessionHistory`;
    }
}

export class GetStrategiesHistoryListByDateRange implements ProtocolQuery {
    constructor(
        public startDate?: Date,
        public endDate?: Date
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategiesHistoryListByDateRange`;
    }
}

export class GetExtraStrategyHistory implements ProtocolQuery {
    constructor(
        public strategyId: string,
        public direction: ExtraStartegyHistoryDirection
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetExtraStrategyHistory`;
    }
}

export enum ExtraStartegyHistoryDirection {
    Unknown = 0,
    Earlier,
    Later
}

export class DeleteArchivedPositionByAggregation implements ProtocolCommand {
    public constructor(
        public accountId: string,
        public terminalId: string,
        public tradingInstrumentCode: string,
        public positionClass: PositionClass
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.DeleteArchivedPositionByAggregation`;
    }
}

export class GetDeletedStrategiesFlagshipReports implements ProtocolQuery {
    public constructor(
        public startDate: Date,
        public endDate: Date,
        public isDispositionReport: boolean
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetDeletedStrategiesFlagshipReports`;
    }
}

export class GetStrategyGameRecordsByDateRange implements ProtocolQuery {
    public constructor(
        public strategyId: string,
        public ticker: string,
        public startDate: Date,
        public endDate: Date,
        public forDeletedStrategy: boolean
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategyGameRecordsByDateRange`;
    }
}

export class GetStrategyTriggers implements ProtocolQuery {
    public constructor(
        public strategyId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategyTriggers`;
    }
}

export class GetRolloverSchedule implements ProtocolQuery {
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetRolloverSchedule`;
    }
}

export class SaveRolloverSchedule implements ProtocolCommand {
    constructor(public descriptors: RolloverDescriptor[]) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SaveRolloverSchedule`;
    }
}

export class RebindStrategies implements ProtocolCommand {
    constructor(
        public strategyId: string[],
        public oldTriggerId: string,
        public newTriggerId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RebindStrategies`;
    }
}

export class SyncOverride implements ProtocolCommand {
    constructor(
        public overrideOptions: AccountOverrideOptions[],
        public strategies: string[],
        public manualPositions: string[],
        public note: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SyncOverride`;
    }
}

export class GetSyncOverrideRecords implements ProtocolQuery {
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSyncOverrideRecords`;
    }
}

export class GetSyncOverrideRecordDetails implements ProtocolQuery {
    constructor(public syncOverrideRecordId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSyncOverrideRecordDetails`;
    }
}

export class RollbackLastOverrideOperation implements ProtocolCommand {
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RollbackLastOverrideOperation`;
    }
}

export class DeleteSyncOverrideRecord implements ProtocolCommand {
    constructor(public recordId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.DeleteSyncOverrideRecord`;
    }
}

export class GetUnreadShellMessages implements ProtocolQuery {
    constructor() {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetUnreadShellMessages`;
    }
}

export class AckShellMessages implements ProtocolCommand {
    constructor(public shellMessageIds: number[]) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.AckShellMessages`;
    }
}

export class RequestLoginIssuesReport implements ProtocolCommand {
    constructor() {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RequestLoginIssuesReport`;
    }
}

export class PlaceCustomExitOrders implements ProtocolCommand {
    constructor(
        public strategyId: string,
        public ticker: string,
        public npoOrder?: CustomExitOrderDescriptor,
        public slOrder?: CustomExitOrderDescriptor
    ) {
        if (!npoOrder && !slOrder) {
            throw new Error('NPO or SL order must be provided');
        }
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.PlaceCustomExitOrders`;
    }
}

export class CancelAllCustomExitOrders implements ProtocolCommand {
    constructor(public strategyId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CancelAllCustomExitOrders`;
    }
}

export class CancelCustomExitOrder implements ProtocolCommand {
    constructor(public orderId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CancelCustomExitOrder`;
    }
}

export class GetCustomExitNpoTotalQtyForStrategy implements ProtocolQuery {
    constructor(public strategyId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetCustomExitNpoTotalQtyForStrategy`;
    }
}

export class GetOptionChainShell implements ProtocolQuery {
    constructor(
        public readonly underlyingTicker: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetOptionChainShell`;
    }
}


//#region Portfolios

export class GetPortfolios implements ProtocolQuery {
    constructor(public includeArchived?: boolean) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPortfolios`;
    }
}


export class ExcludePortfolioItem implements ProtocolCommand {
    constructor(
        public readonly itemId: string,
        public readonly portfolioId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ExcludePortfolioItem`;
    }
}


export class ArchivePortfolioItem implements ProtocolCommand {
    constructor(
        public readonly itemId: string,
        public readonly portfolioId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ArchivePortfolioItem`;
    }
}


export class CreatePortfolio implements ProtocolCommand {
    constructor(
        public readonly portfolioName: string,
        public readonly terminalId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreatePortfolio`;
    }
}


export class GetPortfolioTrades implements ProtocolQuery {
    constructor(
        public readonly strategyId: string,
        public readonly portfolioId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPortfolioTrades`;
    }
}


export class GetPortfolioMessages implements ProtocolQuery {
    constructor(
        public readonly strategyId: string,
        public readonly portfolioId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPortfolioMessages`;
    }
}


export enum BucketItemTransferType {
    Unknown = 0,
    Relocate,
    Exclude,
    Archive,
    Include
}

export interface BucketSpec {
    terminalId?: string;
    portfolioId?: string;
    comboId?: string;
    comboGroupId?: string;

    newPortfolioName?: string;
    newComboName?: string;
    newComboGroupName?: string;
}

export enum BucketItemRelocateType {
    Unknown,

    /// <summary>
    /// Move item completely, with all accumulated and floating PnL available
    /// </summary>
    WithPnL,

    /// <summary>
    /// Move item, but leave all accumulated and floating profit
    /// </summary>
    WithoutPnL,

    /// <summary>
    /// Split positions and move part of it, emulating new fill price and qty
    /// </summary>
    Split
}

export interface RelocateSplitOptions {
    price?: number;
    qty?: number;
}

export interface BucketItemRelocateSpec {
    relocateType: BucketItemRelocateType;
    splitOptions?: RelocateSplitOptions;
}

export interface BucketItemTransferSpec {
    transferType: BucketItemTransferType;
    destinationBucketSpec?: BucketSpec;
    relocateSpec?: BucketItemRelocateSpec;
}

export class TransferBucketItem implements ProtocolCommand {
    public constructor(
        public readonly itemId: string,
        public readonly transferSpec: BucketItemTransferSpec
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.TransferBucketItem`;
    }
}


export class BulkTransferPortfolioItems implements ProtocolCommand {

    constructor(public readonly commands: TransferBucketItem[]) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.BulkTransferPortfolioItems`;
    }
}

//#endregion
export interface OrderLegDto {
    action: MarketSide;
    qty: number;
    ticker: string;
    execPrice?: number;
    execDate?: string;
    execMilliseconds: number;
    terminalId: string;
    portfolioId?: string;
    comboId?: string;
    comboGroupId?: string;
    newPortfolioName?: string;
    newComboName?: string;
    newComboGroupName?: string;
}

export interface MultiLegOrderDto {
    underlying: string;
    orderType: OrderType;
    limitPrice: number;
    legs: OrderLegDto[];
    terminalId: string;
    accountId: string;
    portfolioId: string;
    portfolioName: string;
    comboName: string;
    comboId: string;
    comboGroupId: string;
    comboGroupName: string;
    tif: TimeInForce;
    isEmulatorTrade: boolean;
    isMultiBucket: boolean;
    qty: number;
    autoLimitPrice: 'Bid' | 'Ask' | 'Mid';
}

export class MultiLegTradeDto {
    constructor(
        public readonly mainOrder: MultiLegOrderDto
    ) {
    }

    linkedOrderA?: MultiLegOrderDto;
    linkedOrderB?: MultiLegOrderDto;
    linkAType?: OrderLinkType;
    linkBType?: OrderLinkType;
    mainOrderAttachment?: CreateAdjustmentStrategy;
    linkedOrderAAttachment?: CreateAdjustmentStrategy;
    linkedOrderBAttachment?: CreateAdjustmentStrategy;
    futureOrderSettings: FutureTimeSettings;
    convertToMarketSettings: ConvertToMarketSettings;
    cashFlowSpec: CashFlowAdjustmentSpec;
}

export class PlaceMultiLegTrades implements ProtocolCommand {

    constructor(public trades: MultiLegTradeDto[]) {

    }

    guiToken: string;

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.PlaceMultiLegTrades`;
    }
}

export class GetAvailableCombos implements ProtocolQuery {
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetAvailableCombos`;
    }
}


export class RenameBucket implements ProtocolCommand {
    constructor(public bucketId: string, public newBucketName: string, public bucketType: 'portfolio' | 'combo' | 'group') {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RenameBucket`;
    }
}


export interface GetLedgerRecordsReply {
    ledgerRecords: LedgerRecord[];
    allRecordsLoaded: boolean;
}

export class GetLedgerRecords implements ProtocolQuery {
    constructor(
        public readonly spec: LedgerSpec,
        public readonly numberOfRecordsToLoad: number,
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetLedgerRecords`;
    }
}

export class CreateCombo implements ProtocolCommand {

    constructor(
        public readonly portfolioId: string,
        public readonly comboName: string,
        public readonly templateName: string,
        public readonly isAuto: boolean
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateCombo`;
    }
}

export class CreateComboGroup implements ProtocolCommand {

    constructor(
        public portfolioId: string,
        public comboId: string,
        public comboGroupName: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateComboGroup`;
    }
}


export class DeleteBucket implements ProtocolCommand {
    constructor(public bucketId: string, public bucketType: 'portfolio' | 'combo' | 'group') {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.DeleteBucket`;
    }
}


export class SaveLedgerRecordNote implements ProtocolCommand {
    constructor(
        public ledgerRecordId: number,
        public note: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SaveLedgerRecordNote`;
    }
}

export class GenerateTradingInstrumentShell implements ProtocolCommand {
    constructor(
        public ticker: string,
        public description: string,
        public exchange: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GenerateTradingInstrumentShell`;
    }
}

export class GetStrategyHedgedPositions implements ProtocolQuery {
    constructor(
        public strategyId: string,
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategyHedgedPositions`;
    }
}

export class GetPortfolioPositions implements ProtocolQuery {
    constructor(
        public bucketType: string,
        public bucketId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPortfolioPositions`;
    }
}

export class GetCombosByPortfolioId implements ProtocolQuery {
    constructor(public portfolioId: string, public includeArchived?: boolean) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetCombosByPortfolioId`;
    }
}

export class GetCombosByTerminal implements ProtocolQuery {
    constructor(public terminalId: string, public includeArchived?: boolean) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetCombosByTerminal`;
    }
}

export class GetComboGroupsByComboId implements ProtocolQuery {
    constructor(public comboId: string, public includeArchived?: boolean) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetComboGroupsByComboId`;
    }
}

export class GetAvailableBuckets implements ProtocolQuery {
    constructor(public includeArchived?: boolean) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetAvailableBuckets`;
    }
}

export class GetBucketItems implements ProtocolQuery {
    constructor(
        public bucketType: BucketType,
        public bucketId: string,
        public onlyTopLevel?: boolean,
        public excludeStrategies?: boolean,
        public excludeArchived?: boolean
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetBucketItems`;
    }
}


export interface BucketContext {
    bucketType?: BucketType;
    bucketId?: string;
    bucketName?: string;
}

export interface ManualPositionsContext {
    ticker: string;
    terminalId: string;
    accountId: string;
}


/**
 * @returns GetTradingDataResponse
 */
export class GetWorkingOrdersWeb implements ProtocolQuery {
    constructor(
        public readonly strategyId: string,
        public readonly mode?: OrdersAndTradesLinkedContext,
        public readonly symbolContext?: string,
        public readonly manualPositionsContext?: ManualPositionsContext,
        public readonly bucketContext?: BucketContext
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetWorkingOrdersWeb`;
    }
}

/**
 * @returns GetTradingDataResponse
 */
export class GetTradesWeb implements ProtocolQuery {
    constructor(
        public readonly strategyId: string,
        public readonly historyStop: number,
        public readonly batchSize: number,
        public readonly mode?: OrdersAndTradesLinkedContext,
        public readonly symbolContext?: string,
        public readonly manualPositionsContext?: ManualPositionsContext,
        public readonly bucketContext?: BucketContext
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetTradesWeb`;
    }
}

/**
 * @returns GetTradingDataResponse
 */
export class GetOrderStateSnapshotsWeb {
    constructor(
        public readonly strategyId: string,
        public readonly historyStop: number,
        public readonly batchSize: number,
        public readonly mode?: OrdersAndTradesLinkedContext,
        public readonly symbolContext?: string,
        public readonly manualPositionsContext?: ManualPositionsContext,
        public readonly bucketContext?: BucketContext
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetOrderStateSnapshotsWeb`;
    }
}


export class ExpireOptions implements ProtocolCommand {

    constructor(
        public readonly expirationItems: ExpirationItem[]
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ExpireOptions`;
    }

}

export class ArchiveBucket implements ProtocolCommand {
    constructor(public readonly bucketId: string, public readonly bucketType: 'Portfolio' | 'Combo' | 'ComboGroup') {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ArchiveBucket`;
    }
}

export class TransferBucket implements ProtocolCommand {
    constructor(
        public readonly bucketId: string,
        public readonly bucketType: 'Portfolio' | 'Combo' | 'ComboGroup',
        public readonly transferSpec: BucketItemTransferSpec) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.TransferBucket`;
    }
}

export class ChangeOptionStrategiesSubscriptionShell implements ProtocolCommand {
    constructor(
        public readonly unsubscribe: string[],
        public readonly subscribe: string[]
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ChangeOptionStrategiesSubscriptionShell`;
    }
}

export interface MarketDataRequestSpec {
    ticker: string;
    isTimesAndSales?: boolean;
    isLevel2?: boolean;
}

export class ChangeMarketDataSubscriptionShell implements ProtocolCommand {
    constructor(
        public readonly unsubscribe: MarketDataRequestSpec[],
        public readonly subscribe: MarketDataRequestSpec[]
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ChangeMarketDataSubscriptionShell`;
    }
}

export class GetNoBucketSummaries implements ProtocolQuery {
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetNoBucketSummaries`;
    }
}

export class GetNoBucketManualPositions implements ProtocolQuery {
    constructor(
        public readonly terminalId?: string,
        public readonly accountId?: string,
        public readonly ticker?: string,
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetNoBucketManualPositions`;
    }
}

export class TogglePositionFlag implements ProtocolCommand {

    constructor(public readonly positionId: string, public readonly flag: PositionFlags) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.TogglePositionFlag`;
    }
}

export class StoreUserSettings implements ProtocolCommand {

    constructor(public readonly settings: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.StoreUserSettings`;
    }

}

export class GetUserSettings implements ProtocolQuery {

    constructor(
        public readonly userId?: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetUserSettings`;
    }
}

//

export interface TransferOrderSpec {
    itemId: string;
    terminalId?: string;
    portfolioId?: string;
    comboId?: string;
    comboGroupId?: string;
}

export class MoveOrderToBucket implements ProtocolCommand {

    constructor(public transferSpec: TransferOrderSpec) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.MoveOrderToBucket`;
    }
}

//

export interface DuplicateBucketSpec {
    bucketId?: string;
    bucketType?: BucketType;
    newName?: string;
    terminalId?: string;
    portfolioId?: string;
    comboId?: string;
    includingItems?: boolean;
}

export class DuplicateBucket implements ProtocolCommand {

    constructor(public duplicateSpec: DuplicateBucketSpec) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.DuplicateBucket`;
    }
}

export class SubscribeForJobProgressMessagesShell implements ProtocolCommand {
    constructor(public jobId?: string) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SubscribeForJobProgressMessagesShell`;
    }
}

export class CreateAdjustmentStrategy implements ProtocolCommand {

    constructor(
        public readonly algoId: string,
        public readonly displayName: string,
        public readonly baseLegPositionId: string,
        public readonly parameters: string
    ) {
    }

    legToAttachTo: string;

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateAdjustmentStrategy`;
    }
}

export class UpdateAdjustmentStrategy implements ProtocolCommand {
    constructor(
        public readonly strategyId: string,
        public readonly displayName: string,
        public readonly parameters: string
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.UpdateAdjustmentStrategy`;
    }
}

export class GetLegUnderAdjustment implements ProtocolQuery {

    constructor(public readonly strategyId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetLegUnderAdjustment`;
    }

}

export class SaveBucketAttributes implements ProtocolCommand {
    constructor(
        public readonly bucketId: string,
        public readonly bucketType: BucketType,
        public readonly attributes: BucketAttributes
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SaveBucketAttributes`;
    }
}

export class GetBucketAttributes implements ProtocolQuery {
    constructor(public readonly bucketId: string) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetBucketAttributes`;
    }
}

export class CreateInterestCalculatorStrategy implements ProtocolCommand {
    constructor(
        public bucketId: string,
        public bucketType: BucketType,
        public displayName: string,
        public amount: number,
        public rate: number,
        public accountId: string,
        public repeat: 'Daily' | 'Weekly',
        public everyXDay: number,
        public everyXWeek: number,
        public mon: boolean,
        public tue: boolean,
        public wed: boolean,
        public thu: boolean,
        public fri: boolean,
        public timeOfDay: string,
        public timezone: string,
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateInterestCalculatorStrategy`;
    }
}

export class ConvertLimitToMarket implements ProtocolCommand {
    constructor(
        public orderId: string,
        public settings: ConvertToMarketSettings
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ConvertLimitToMarket`;
    }
}

export class AddInterestRecord implements ProtocolCommand {
    constructor(
        public strategyId: string,
        public amount: number,
        public rate: number,
        public daysInYear: number,
        public daysOfInterest: number
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.AddInterestRecord`;
    }
}

export class ToggleCashSettled implements ProtocolCommand {
    constructor(public readonly positionId: string) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ToggleCashSettled`;
    }
}

export class ArchiveStrategy implements ProtocolCommand {

    constructor(public readonly strategyId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ArchiveStrategy`;
    }

}

export class ReadShellMessages implements ProtocolCommand {
    constructor(public shellMessageIds: number[]) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ReadShellMessages`;
    }
}

export class UpdateOrderTimeSettings implements ProtocolCommand {
    constructor(
        public orderId: string,
        public futureSettings: FutureTimeSettings,
        public convertToMarketSettings: FutureTimeSettings
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.UpdateOrderTimeSettings`;
    }
}

export class RollLegUnderAdjustment implements ProtocolCommand {
    constructor(
        public readonly positionId: string,
        public readonly targetLegTicker: string,
        public readonly orderType: OrderType,
        public readonly limitPrice: number,
        public readonly autoPrice: string,
        public readonly futurePlacementSettings: FutureTimeSettings,
        public readonly convertToMarketSettings: ConvertToMarketSettings,
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RollLegUnderAdjustment`;
    }
}

export class GetComboData implements ProtocolQuery {
    constructor(public readonly comboId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetComboData`;
    }
}

export class CreateInterestStrategy implements ProtocolCommand {
    constructor(
        public parameters: string,
        public displayName: string,
        public bucketId: string,
        public bucketType: BucketType) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateInterestStrategy`;
    }
}

export class ToggleStrategyState implements ProtocolCommand {
    constructor(public strategyId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ToggleStrategyState`;
    }
}

export class ChangeAttachedLeg implements ProtocolCommand {
    constructor(public positionId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ChangeAttachedLeg`;
    }
}

//
export class RollShortTermPutDebitSpreadManually implements ProtocolCommand {
    constructor(
        public readonly expiration: string,
        public readonly spreadWidth: number,
        public readonly atmOffset: number,
        public readonly atmNeutralZone: number,
        public readonly orderType: number,
        public readonly autoLimitPrice: string,
        public readonly convertToMarket: boolean,
        public readonly convertToMarketSettings: ConvertToMarketSettings,
        public readonly bucketSpec: BucketSpec
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RollPShortTermPutDebitSpreadManually`;
    }
}

//
export class CreateStrategiesInAutomatedMode implements ProtocolCommand {
    constructor(
        public readonly comboId: string,
        public readonly shortOptionName: string,
        public readonly shortOptionParameters: string,
        public readonly spreadRollName: string,
        public readonly spreadRollParameters: string,
        public readonly spreadRollParameters2: string,
        public readonly spreadAdjustName: string,
        public readonly spreadAdjustParameters: string,
        public readonly longOptionName: string,
        public readonly longOptionParameters: string,
        public readonly longOptionParameters2: string,
        public readonly interestName: string,
        public readonly interestParameters: string,
        public readonly protectQty?: number
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateStrategiesInAutomatedMode`;
    }
}

//
export class EnableStrategiesInAutomatedMode implements ProtocolCommand {

    constructor(
        public readonly comboId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.EnableStrategiesInAutomatedMode`;
    }

}

//
export class DisableStrategiesInAutomatedMode implements ProtocolCommand {

    constructor(
        public readonly comboId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.DisableStrategiesInAutomatedMode`;
    }

}

//
export class UpdateStrategiesInAutomatedMode implements ProtocolCommand {
    constructor(
        public readonly comboId: string,
        public readonly shortOptionName: string,
        public readonly shortOptionName2: string,
        public readonly shortOptionParameters: string,
        public readonly shortOptionParameters2: string,
        public readonly shortOptionId: string,
        public readonly shortOptionId2: string,
        public readonly spreadRollName: string,
        public readonly spreadRollName2: string,
        public readonly spreadRollParameters: string,
        public readonly spreadRollParameters2: string,
        public readonly spreadRollId: string,
        public readonly spreadRollId2: string,
        public readonly spreadAdjustName: string,
        public readonly spreadAdjustParameters: string,
        public readonly longOptionName: string,
        public readonly longOptionName2: string,
        public readonly longOptionParameters: string,
        public readonly longOptionParameters2: string,
        public readonly longOptionId: string,
        public readonly longOptionId2: string,
        public readonly interestName: string,
        public readonly interestParameters: string,
        public readonly protectQty?: number
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.UpdateStrategiesInAutomatedMode`;
    }
}

//
export interface AdjustmentPricingSettingsDto {
    templateId: string;
    templateName: string;

    strategy: CashFlowStrategy;
    underlying: string;
    comboId: string;
    priceToDestination: boolean;
    priceToOpen: boolean;
    beforeState: BeforePositionDto[];
    isStrategyAdvancedMode: boolean;
    isDynamicOffsets: boolean;
    theoreticalPriceIv: number;
    theoreticalPriceMode: string;
    theoreticalPriceIvList: string;

    //
    spreadOffset: number;
    spreadWidth: number;
    spreadRollXDaysBeforeExpiration: number;
    spreadRollToDaysToExp: number;
    spreadOverrideRollToDaysToExp: string;
    spreadEvergreenOverrideRollToDaysToExp: string;

    //
    secondSpreadOffset: number;
    secondSpreadWidth: number;
    secondSpreadRollXDaysBeforeExpiration: number;
    secondSpreadRollToDaysToExp: number;
    secondSpreadOverrideRollToDaysToExp: string;
    secondSpreadEvergreenOverrideRollToDaysToExp: string;

    //
    protectiveOptionOffset: number;
    protectiveOptionRollXDaysBeforeExpiration: number;
    protectiveOptionRollToDaysToExp: number;
    protectiveOptionOverrideRollToDaysToExp: string;
    protectiveOptionEvergreenOverrideRollToDaysToExp: string;
    protectiveOptionRollToXBusinessDaysToExp: number;

    //
    secondProtectiveOptionOffset: number;
    secondProtectiveOptionRollXDaysBeforeExpiration: number;
    secondProtectiveOptionRollToDaysToExp: number;
    secondProtectiveOptionOverrideRollToDaysToExp: string;
    secondProtectiveOptionEvergreenOverrideRollToDaysToExp: string;
    secondProtectiveOptionRollToXBusinessDaysToExp: number;

    //
    customDates: string[];
    joinRollBuffer: number;
    expirationsToLookForward: number;
    expirationsToLookForwardAbove: number;
    expirationsToLookForwardDown: number;

    //
    expirationSmartModeSettings: IExpirationSmartModeSettings
    useMarginEfficientAdjustment: boolean;

    adjustToQty?: number;

    zonesGridRange?: number;
    zonesGridRangeStep?: number;
    zonesGridAdjustment?: string;
    zonesGridRangeDown?: number;

    overrideAtm?: number;

    useCharlesSchwabFix: boolean;
}

//
export class GetAdjustmentSolutions implements ProtocolCommand {
    constructor(
        public readonly requestId: string,
        public readonly adjustmentPricingSettings: AdjustmentPricingSettingsDto[],
        public readonly beforeStates?: any[]
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetAdjustmentSolutions`;
    }
}

//
export class CreateCashFlowAutoAdjustmentStrategy implements ProtocolCommand {
    constructor(
        public readonly comboId: string,
        public readonly strategyId: string,
        public readonly globalSettings: GetGlobalSettingsSectionDataModelReply,
        public readonly shortOption: GetShortOptionSectionDataModelReply,
        public readonly debitSpread: GetDebitSpreadSectionDataModelReply,
        public readonly protectiveOption: GetProtectiveOptionSectionDataModelReply,
        public readonly interest: InterestParameters
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CreateCashFlowAutoAdjustmentStrategy`;
    }
}

//
export class GetDebitSpreadSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
        public readonly cashFlowStrategy: CashFlowStrategy
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetDebitSpreadSectionDataModel`;
    }
}

//
export class GetProtectiveOptionSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
        public readonly cashFlowStrategy: CashFlowStrategy
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetProtectiveOptionSectionDataModel`;
    }
}

//
export class GetShortOptionSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
        public readonly cashFlowStrategy: CashFlowStrategy
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetShortOptionSectionDataModel`;
    }
}

//
export class GetOverviewSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
        public readonly cashFlowStrategy: CashFlowStrategy
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetOverviewSectionDataModel`;
    }
}

//
export class GetInterestSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetInterestSectionDataModel`;
    }
}

//
export class GetPortfolioSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPortfolioSectionDataModel`;
    }
}

//
export class GetWorkingOrdersSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetWorkingOrdersSectionDataModel`;
    }
}

//
export class GetGlobalSettingsSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetGlobalSettingsSectionDataModel`;
    }
}

//
export class GetStrategyControlsSectionDataModel implements ProtocolQuery {
    constructor(
        public readonly comboId: string,
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetStrategyControlsSectionDataModel`;
    }
}


//
export interface GetCashFlowTrackingRecordsReply {
    records: CashFlowTrackingRecordDto[];
    allRecordsLoaded: boolean;
}

//
export class GetCashFlowTrackingRecords implements ProtocolQuery {
    constructor(
        public readonly spec: { comboId?: string },
        public readonly numberOfRecordsToLoad: number,
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetCashFlowTrackingRecords`;
    }
}

//
export class GetAssignableAdjustmentTemplates implements ProtocolQuery {
    constructor() {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetAssignableAdjustmentTemplates`;
    }
}

//
export class AssignAdjustmentTemplates implements ProtocolQuery {
    constructor(
        public readonly templates: AdjsutmentTemplateToClientsMap[]
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.AssignAdjustmentTemplates`;
    }
}

//
export class SaveAssignableAdjustmentTemplateShell implements ProtocolCommand {
    constructor(
        public readonly templateId: string,
        public readonly template: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SaveAssignableAdjustmentTemplateShell`;
    }
}

//
export class GetAssignedAdjustmentTemplates implements ProtocolQuery {
    constructor(
        public readonly userId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetAssignedAdjustmentTemplates`;
    }
}

//
export class RemoveAssignableAdjustmentTemplateShell implements ProtocolCommand {
    constructor(
        public readonly templateId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RemoveAssignableAdjustmentTemplateShell`;
    }
}

//
export class SaveChecklistStepsShell implements ProtocolQuery {
    constructor(
        public readonly steps: ChecklistStepDto[]
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SaveChecklistStepsShell`;
    }
}

//
export class RemoveChecklistStepShell implements ProtocolCommand {
    constructor(
        public readonly checklistStepId: number
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RemoveChecklistStepShell`;
    }
}

//
export class ToggleChecklistStepStateShell implements ProtocolCommand {
    constructor(
        public readonly checklistStepId: number
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ToggleChecklistStepStateShell`;
    }
}

//
export class GetChecklistStepsShell implements ProtocolCommand {
    constructor(
        public readonly strategy: CashFlowStrategy,
        public readonly adjustment: CashFlowAdjustmentLogicalId,
        public readonly includeDrafts: boolean
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetChecklistStepsShell`;
    }
}

//
export class WithdrawAllChecklistStepsShell implements ProtocolCommand {
    constructor(
        public readonly strategy: CashFlowStrategy,
        public readonly adjustment: CashFlowAdjustmentLogicalId,
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.WithdrawAllChecklistStepsShell`;
    }
}

//
export class GetTerminalsForAutomationCp implements ProtocolQuery {
    constructor(
        public readonly automationCpMode: AutomationCpMode,
        public readonly strategy: CashFlowStrategy,
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetTerminalsForAutomationCp`;
    }
}

//
export class GetPortfoliosForAutomationCp implements ProtocolQuery {
    constructor(
        public readonly automationCpMode: AutomationCpMode,
        public readonly strategy: CashFlowStrategy,
        public readonly terminalId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetPortfoliosForAutomationCp`;
    }
}

//
export class GetCombosForAutomationCp implements ProtocolQuery {
    constructor(
        public readonly automationCpMode: AutomationCpMode,
        public readonly strategy: CashFlowStrategy,
        public readonly portfolioId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetCombosForAutomationCp`;
    }
}

//
export class GetBucketSummaryForAutomationCp implements ProtocolQuery {
    constructor(
        public readonly comboId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetBucketSummaryForAutomationCp`;
    }
}

//
export class DeleteCashFlowAutoAdjustmentStrategy implements ProtocolCommand {
    constructor(
        public readonly comboId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.DeleteCashFlowAutoAdjustmentStrategy`;
    }
}

//
export class EnableCashFlowAdjustmentStrategy implements ProtocolCommand {
    constructor(
        public readonly strategyId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.EnableCashFlowAdjustmentStrategy`;
    }
}

//
export class DisableCashFlowAdjustmentStrategy implements ProtocolCommand {
    constructor(
        public readonly strategyId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.DisableCashFlowAdjustmentStrategy`;
    }
}

//
export class GetFreshClientPortfoliosData implements ProtocolQuery {
    constructor(
        public readonly userId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetFreshClientPortfoliosData`;
    }
}

//
export interface GetFreshClientPortfoliosDataReply {
    settings: string;
}

//
export class ApgPortfolioStateChanged implements ProtocolCommand {
    constructor(
        public readonly apgPortfolioId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ApgPortfolioStateChanged`;
    }
}

//
export class StartSweetPriceTracking implements ProtocolCommand {
    constructor(
        public readonly userId: string,
        public readonly portfolio: ApgPortfolio,
        public readonly sweetPriceSettings: SweetPriceSettings,
        public readonly positions: BeforePositionDto[][],
        public readonly expirationSmartModeSettings: IExpirationSmartModeSettings,
        public readonly hedges: HedgePosition[]
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.StartSweetPriceTracking`;
    }
}

//
export interface StartSweetPriceTrackingReply {
    template: TrackingTemplate;
}

//
export class StopSweetPriceTracking implements ProtocolCommand {
    constructor(
        public readonly userId: string,
        public readonly portfolio: ApgPortfolio,
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.StopSweetPriceTracking`;
    }
}

//
export class ResolveExpirationDates implements ProtocolQuery {
    constructor(
        public readonly underlying: string,
        public readonly shortOptionTicker: string,
        public readonly daysToLookForward: number,
        public readonly includeStartDate: boolean
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ResolveExpirationDates`;
    }
}

export interface ResolveExpirationDatesReply {
    expirations: string[];
}

// export class GetSweetPriceTrackingSettings implements ProtocolQuery {
//    constructor(
//       public readonly userId: string,
//       public readonly portfolioId: string
//    ) {}
//    getOperationName(): string {
//       return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSweetPriceTrackingSettings`;
//    }
// }

// export interface GetSweetPriceTrackingSettingsReply {
//    positions: BeforePositionDto[][];
//    templateId: string;
// }

export class GetSweetPriceTrackingDataForPortfolio implements ProtocolQuery {
    constructor(
        public readonly userId: string,
        public readonly portfolioId: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetSweetPriceTrackingDataForPortfolio`;
    }
}

//
export interface GetSweetPriceTrackingDataForPortfolioReply {
    positions: BeforePositionDto[][];
    template: TrackingTemplate;
    sweetPriceSettings: SweetPriceSettings;
}

//
export class UpdateTrackingObjectsOnTemplateUpdate implements ProtocolCommand {
    constructor(
        public readonly userId: string,
        public readonly template: TrackingTemplate
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.UpdateTrackingObjectsOnTemplateUpdate`;
    }
}

//
export class UpdateTrackingObjectOnSweetPriceSettingsUpdate implements ProtocolCommand {
    constructor(
        public readonly userId: string,
        public readonly portfolio: ApgPortfolio,
        public readonly settings: SweetPriceSettings
    ) {

    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.UpdateTrackingObjectOnSweetPriceSettingsUpdate`;
    }
}

//
export class GetApgDefaultSettingsShell implements ProtocolQuery {

    constructor() {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetApgDefaultSettingsShell`;
    }
}

export interface GetApgDefaultSettingsShellReply {
    settings: ApgDefaultSettingsDto[];
}

//
export class SaveApgDefaultSettingsShell implements ProtocolCommand {
    constructor(public readonly settings: ApgDefaultSettingsDto[]) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SaveApgDefaultSettingsShell`;
    }
}

export class GetResourceButtonsShell implements ProtocolCommand {
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetResourceButtonsShell`;
    }
}

export interface GetResourceButtonsShellReply {
    buttons: ResourceButton[];
}

export class RemoveResourceButtonShell implements ProtocolCommand {
    constructor(public readonly resourceButtonId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.RemoveResourceButtonShell`;
    }
}

export class SaveResourceButtonsShell implements ProtocolQuery {
    constructor(public readonly buttons: ResourceButton[]) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SaveResourceButtonsShell`;
    }
}

export interface SaveResourceButtonsShellReply {
    ids: {item1: number, item2: number}[];
}

export class ToggleResourceButtonStateShell implements ProtocolCommand {
    constructor(public readonly resourceButtonId: string) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.ToggleResourceButtonStateShell`;
    }
}

export class SetImpliedVolatilityShell implements ProtocolCommand {

    constructor(
        public readonly underlying: string,
        public readonly volatilities: string
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SetImpliedVolatilityShell`;
    }
}


export class GetImpliedVolatility implements ProtocolQuery {
    constructor(
        public readonly underlying: string,
        public readonly expiration: string
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetImpliedVolatility`;
    }
}

export interface GetImpliedVolatilityReply {
    expiration: string;
    volatility: number;
}

export class CalculateCashFlowHedges implements ProtocolQuery {
    constructor(
        public readonly requestId: string,
        public readonly priceToOpen: boolean,
        public readonly adjToQty: number,
        public readonly hedgePositionGroupsBySides: HedgePositionGroupsBySide[]
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CalculateCashFlowHedges`;
    }
}

export interface CalculateCashFlowHedgesReply {
    hedgeStateTransactions: HedgeStateTransaction[]
}

export class CalculateZonesGridHedges implements ProtocolQuery {
    constructor(
        public readonly zonesGridRange: number,
        public readonly zonesGridRangeStep: number,
        public readonly zonesGridRangeDown: number,
        public readonly hedges: HedgePositionGroup[],
        public readonly portfolio: BeforePositionDto[]
    ) {
    }

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CalculateZonesGridHedges`;
    }
}

export interface CalculateZonesGridHedgesReply {
    hedges: HedgePositionGroup[];
    atmAtCalculation: number;
}

export class GetQuoteSubscriptionsCount implements ProtocolQuery {
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetQuoteSubscriptionsCount`;
    }
}

export interface GetQuoteSubscriptionsCountResponse {
    count: string;
}

export class CalculateRollDates implements ProtocolQuery {
    constructor(
        public readonly underlying: string,
        public readonly daysToLookForward: number,
        public readonly customDates: string[],
        public readonly startDate: string
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CalculateRollDates`;
    }
}

export interface CalculateRollDatesReply {
    dates: string[];
}

export class CalculatePackageComparison implements ProtocolCommand {
    constructor(
        public readonly packageId: string,
        public readonly portfolio: ApgPortfolio,
        public readonly positions: BeforePositionDto[][],
        public readonly hedgePositions: HedgePosition[],
        public readonly template: string,
        public readonly expiration: string,
        public readonly adjustment: string,
        public readonly callHedge: PricingGridStrategyDescriptor,
        public readonly callHedgeOffset: number,
        public readonly putHedge: PricingGridStrategyDescriptor,
        public readonly putHedgeOffset: number,
        public readonly expirationSmartModeSettings: IExpirationSmartModeSettings,
        public readonly atm: number,
        public readonly useMarginEfficientAdjustment: boolean,
        public readonly useCharlesSchwabFix: boolean
    ) {
    }

    delta = 0;

    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CalculatePackageComparison`;
    }
}

export interface CalculatePackageComparisonResult {
    packageId: string;
    type: 'Adjustment' | 'Hedges';
    adjustments: CashFlowAdjustment[];
    hedges: HedgeStateTransaction[];
    atmStrike: number;
}

export class StoreUserSettings2 implements ProtocolCommand {
    constructor(
        public readonly settings: UserSettingDto[]
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.StoreUserSettings2`;
    }
}

export class CalculatePackageComparisonBulk implements ProtocolCommand {
    constructor(
        public readonly comparisons: CalculatePackageComparison[],
        public readonly delta: number,
        public readonly isHedgesDashboard?: boolean
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.CalculatePackageComparisonBulk`;
    }
}

//
export class GetUserSettingsData implements ProtocolQuery {
    constructor(
        public readonly userId: string,
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetUserSettingsData`;
    }
}

export class SaveUserSetting implements ProtocolCommand {
    constructor(
        public readonly key: string,
        public readonly value: string,
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.SaveUserSetting`;
    }
}

export class ForceGetUserSetting implements ProtocolQuery {
    constructor(
        public readonly userId: string,
        public readonly keys: string[],
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.GetUserSetting`;
    }
}

export class DeleteUserSetting implements ProtocolCommand {
    constructor(
        public readonly keys: string[],
    ) {
    }
    getOperationName(): string {
        return `${SHELL_PROTOCOL_OPERATIONS_NS}.DeleteUserSetting`;
    }
}
