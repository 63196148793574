import {Injectable} from '@angular/core';
import {ShellClientService} from 'projects/shared-components/shell-communication/shell-client.service';
import {
    GetSweetPriceTrackingDataForPortfolio,
    GetSweetPriceTrackingDataForPortfolioReply,
    StartSweetPriceTracking,
    StopSweetPriceTracking
} from 'projects/shared-components/shell-communication/shell-operations-protocol';
import {
    SweetPriceSettings, SweetPriceSettingsValidationContext,
    SweetPriceTrackingSettings
} from '../positions-section/tracking-dialog/SweetPriceTrackingSettings';
import {isValidNumber, isVoid} from 'projects/shared-components/utils';
import {BeforePositionDto} from '../model/BeforePositionDto';
import {ApgPortfolio} from '../model/ApgPortfolio';
import {IExpirationSmartModeSettings} from "../../app-settings/model/ExpirationSmartModeSettings";
import {TrackingStartedMessage, TrackingStoppedMessage} from "../../shell-communication/shell-dto-protocol";
import {UserSettingsService} from "../../user-settings.service";
import {HedgePosition} from "../../hedging-grid/data-model/hedge-position";

@Injectable()
export class SweetPriceTrackingService {

    constructor(
        private _shellClient: ShellClientService,
        private _userSettingsService: UserSettingsService
    ) {
    }

    private _trackingObjects: Record<string, SweetPriceTrackingSettings> = {};


    //
    async startTracking(
        userId: string,
        portfolio: ApgPortfolio,
        positions: BeforePositionDto[][],
        sweetPriceSettings: SweetPriceSettings,
        expirationSmartModeSettings: IExpirationSmartModeSettings,
        hedges: HedgePosition[],
    ): Promise<void> {

        const qry = new StartSweetPriceTracking(
            userId,
            portfolio,
            sweetPriceSettings,
            positions,
            expirationSmartModeSettings,
            hedges
        );

        await this._shellClient.processCommand(qry);
    }


    //
    async stopTracking(userId: string, portfolio: ApgPortfolio): Promise<void> {

        const cmd = new StopSweetPriceTracking(
            userId,
            portfolio,
        );

        await this._shellClient.processCommand(cmd);
    }

    //
    isSync(userId: string, portfolioId: string, positions: BeforePositionDto[][], templateId: string): boolean {

        if (!this.isTracking(userId, portfolioId)) {
            return true;
        }

        if (isVoid(templateId)) {
            return false;
        }

        if (isVoid(positions)) {
            return false;
        }

        const key = userId + portfolioId;

        const trackingObject = this._trackingObjects[key];

        if (isVoid(trackingObject)) {
            return false;
        }

        const isTemplateSync = templateId === trackingObject.template.templateId;

        const candidatePoses = positions.flatMap(x => x.map(y => y.ticker));

        const tplPoses = trackingObject.positions.flatMap(x => x.map(y => y.ticker));

        const outOfSync = candidatePoses.filter(x => tplPoses.indexOf(x) < 0);

        return isTemplateSync && outOfSync.length === 0;
    }

    //
    saveSweetPriceTrackingSettings(userId: string, portfolioId: string, settings: SweetPriceSettings) {
        const key = `apg.sweetprice.tracking-settings.${portfolioId}`;
        this._userSettingsService.setValue(key, settings, userId);
    }

    //
    getSweetPriceSettings(userId: string, portfolioId: string): SweetPriceSettings {
        const key = `apg.sweetprice.tracking-settings.${portfolioId}`;
        const settings = this._userSettingsService.getValue<SweetPriceSettings>(key, userId);
        return settings;// JSON.parse(settings);
    }

    //
    isTracking(userId: string, portfolioId: string): boolean {
        const key = userId + portfolioId;
        const settings = this._trackingObjects[key];
        return !isVoid(settings);
    }

    //
    async loadPortfolioTrackingData(userContext: string, id: string): Promise<void> {

        const qry = new GetSweetPriceTrackingDataForPortfolio(
            userContext,
            id
        );

        const reply = await this._shellClient.processQuery<GetSweetPriceTrackingDataForPortfolioReply>(qry);

        if (isVoid(reply.template)) {
            return;
        }

        const key = userContext + id;

        this._trackingObjects[key] = {
            positions: reply.positions,
            template: reply.template,
            sweetPriceSettings: reply.sweetPriceSettings
        };
    }

    getSweetPriceTrackingObject(userId: string, portfolioId: string): SweetPriceTrackingSettings {
        const key = userId + portfolioId;
        const obj = this._trackingObjects[key];
        return obj;
    }

    validateSweetPriceSettings(sweetPriceSettings: SweetPriceSettings, ctx: SweetPriceSettingsValidationContext) {
        if (!sweetPriceSettings) {
            throw Error('No sweet price settings');
        }

        //s
        // time window
        //
        if (!sweetPriceSettings.notifyTwentyFourSeven) {
            if (isVoid(sweetPriceSettings.startTime)) {
                throw Error('"Start Time" is required parameter for Sweet Price tracking');
            }

            if (isVoid(sweetPriceSettings.stopTime)) {
                throw Error('"Stop Time" is required parameter for Sweet Price tracking');
            }

            if (isVoid(sweetPriceSettings.timezone)) {
                throw Error('"Stop Time" is required parameter for Sweet Price tracking');
            }
        }

        //
        // interval
        //
        if (!isValidNumber(sweetPriceSettings.notificationInterval, true)) {
            throw Error('Sweet Price notification interval not provided');
        }

        if (isVoid(sweetPriceSettings.notificationStrategy)) {
            throw Error('Sweet Price notification strategy not provided');
        }


        //
        // sweet prices
        //
        if (sweetPriceSettings.sweetPricesByExpiration.every(x => !isValidNumber(x.price))) {
            if (sweetPriceSettings.sweetPricesByAdjustment.length === 0) {
                if (!isValidNumber(sweetPriceSettings.globalSweetPrice)) {
                    throw Error('No sweet prices provided, tracking will have no effect');
                }
            }
        }

        if (sweetPriceSettings.sweetPricesByAdjustment.length > 0) {

            const isSingleSided = ctx.strategy !== 'Calls & Puts';

            const allGood = sweetPriceSettings.sweetPricesByAdjustment.every(x => {
                if (isVoid(x.selectedExpiration)) {
                    return false;
                }

                if (isVoid(x.selectedAdjustment) && ctx.strategy !== 'Puts') {
                    return false;
                }

                if (!isVoid(x.selectedAdjustment) && ctx.strategy === 'Puts') {
                    return false;
                }

                if (isVoid(x.secondSelectedAdjustment) && !isSingleSided) {
                    return false;
                }

                if (!isVoid(x.secondSelectedAdjustment) && isSingleSided && ctx.strategy !== 'Puts') {
                    return false;
                }

                if (!isVoid(x.selectedAdjustment) && !isVoid(x.secondSelectedAdjustment) && ctx.strategy !== 'Calls & Puts') {
                    return false;
                }

                return isValidNumber(x.price, false);

            });

            if (!allGood) {
                throw Error('Incorrect configuration of sweet prices by adjustment type');
            }
        }

        if (sweetPriceSettings.dynamicTemplates.length == 0) {
            throw Error('Incorrect dynamic templates configuration');
        } else {

            const allGood = sweetPriceSettings.dynamicTemplates.every(x => {
                return !isVoid(x.templateId) &&
                    !isVoid(x.dayOfWeek) &&
                    x.templateId.split('^').includes(ctx.underlying);
            });

            if (!allGood) {
                throw Error('Incorrect dynamic templates configuration');
            }
        }

        // global sweet prices
        if (!isVoid(sweetPriceSettings.globalSweetPriceExclusions)) {
            if (!isValidNumber(sweetPriceSettings.globalSweetPrice, true)) {
                throw Error('Incorrect global price');
            }

            const isSingleSided = ctx.strategy !== 'Calls & Puts';
            const globalPriceGood = sweetPriceSettings.globalSweetPriceExclusions.every(x => {

                if (isVoid(x.selectedExpiration)) {
                    return false;
                }

                if (isVoid(x.selectedAdjustment) && ctx.strategy !== 'Puts') {
                    return false;
                }

                if (!isVoid(x.selectedAdjustment) && ctx.strategy === 'Puts') {
                    return false;
                }

                if (isVoid(x.secondSelectedAdjustment) && !isSingleSided) {
                    return false;
                }

                if (!isVoid(x.secondSelectedAdjustment) && isSingleSided && ctx.strategy !== 'Puts') {
                    return false;
                }

                if (!isVoid(x.selectedAdjustment) && !isVoid(x.secondSelectedAdjustment)
                    && ctx.strategy !== 'Calls & Puts') {
                    return false;
                }

                return true;
            });

            if (!globalPriceGood) {
                throw Error('Incorrect configuration of global sweet price with exclusions');
            }
        }

        // Zones
        const zonesAreGood = sweetPriceSettings.priceZones.every(pz => {
            if (!pz.enabled) {
                return false;
            }

            if (!isValidNumber(pz.midPoint, true)) {
                return false;
            }

            if (pz.midPoint < 0) {
                return false;
            }

            if (!isValidNumber(pz.rangeUp)) {
                return false;
            }

            if (pz.rangeUp < 0) {
                return false;
            }

            if (!isValidNumber(pz.rangeDown)) {
                return false;
            }

            if (pz.rangeDown < 0) {
                return false;
            }

            if (isVoid(pz.nickname)) {
                return false;
            }

            return true;
        });

        if (!zonesAreGood) {
            throw Error('Incorrect price zones configuration');
        }

        if (!isValidNumber(sweetPriceSettings.priceZoneAtmNotificationFilter)) {
            if (ctx.priceZoneAtmNotificationFilterEnabled) {
                throw Error('Price Zone ATM notification filter needs a valid number of DTE');
            }
        }

        if (sweetPriceSettings.hedgesToTrack.some(x => !isValidNumber(x.sweetPrice, true))) {
            throw new Error('Selected Hedges Must Have Sweet Prices');
        }

        if (sweetPriceSettings.packagesToTrack.some(x => {
            return !isValidNumber(x.sweetPrice, true) || !isValidNumber(x.expirationSeqNo);
        })) {
            throw new Error('Selected Package Comparisons Must Have Sweet Prices And Expirations');
        }
    }

    onTrackingStartedConfirmation(payload: TrackingStartedMessage) {
        const key = payload.id;

        const positions: BeforePositionDto[][] = JSON.parse(payload.positions);

        this._trackingObjects[key] = {
            positions,
            template: payload.template,
            sweetPriceSettings: payload.sweetPriceSettings
        };
    }

    onTrackingStoppedConfirmation(payload: TrackingStoppedMessage) {
        const key = payload.id
        delete this._trackingObjects[key];
    }
}
