<dx-popup
  (onHidden)="this.onClosed()"
  [height]="this.height"
  [maxHeight]="this.maxHeight"
  [maxWidth]="this.maxWidth"
  [minHeight]="640"
  [minWidth]="780"
  [visible]="this.visible"
  [width]="this.width"
>
  <div *dxTemplate="let data of 'content'">
    
    <div class="root">
      <div class="transaction-pads">
        <div class="transaction" *ngFor="let transaction of this.transactions">
          <ng-container *ngTemplateOutlet="transTpl; context: {
            $implicit: {
              side: transaction.side,
              portfolioName:'Default',
              transaction: transaction
            }
          }"
          ></ng-container>
        </div>
      </div>
      
      <div *ngIf="this.context === 'pkg-cmprsn'">
        <div class="row">
          <hr>
        </div>
        
        <div class="big-button" *ngIf="!this.isDoubleMode">
          <dx-button width="100%" text="Copy Orders" type="default" (onClick)="this.copyToHedgingGrid()">
          </dx-button>
        </div>
        
        <div class="big-button" *ngIf="this.isDoubleMode">
          <div class="copy-btn">
            <dx-button width="100%" text="Copy Calls" type="default" (onClick)="this.copySideToHedgingGrid('Calls')">
            </dx-button>
          </div>
          <div class="copy-btn">
            <dx-button width="100%" text="Copy Both" type="default" (onClick)="this.copyToHedgingGrid()">
            </dx-button>
          </div>
          <div class="copy-btn">
            <dx-button width="100%" text="Copy Puts" type="default" (onClick)="this.copySideToHedgingGrid('Puts')">
            </dx-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</dx-popup>

<ng-template #transTpl let-ctx>
  
  <div class="head">
    
    <div class="headings">
      <div class="heading">
        <div class="label">Type</div>
        <div class="value">"{{ ctx.side | uppercase }}"</div>
      </div>
      <div class="heading">
        <div class="label">Portfolio</div>
        <div class="value">{{ ctx.portfolioName }}</div>
      </div>
    </div>
  </div>
  
  <div [class.collapsed]="!this.getPositionSectionVisibility(ctx.side)" class="section positions">
    
    <div class="section-header">
      <div class="row">
        <hr>
      </div>
      <div class="header-text">
        <div class="text">
          POSITIONS
        </div>
        <div class="switch">
          <div class="wrapper">
            <dx-switch
              (valueChange)="this.onPositionSectionVisibilityChanged(ctx.side)"
              [value]="this.getPositionSectionVisibility(ctx.side)"
            ></dx-switch>
          </div>
        </div>
      </div>
      <div class="row">
        <hr>
      </div>
    </div>
    
    <div [class.collapsed]="!this.getPositionSectionVisibility(ctx.side)" class="section-body">
      
      <div class="before-after-header">
        <div class="before-header">BEFORE</div>
        <div class="after-header">AFTER</div>
        <div class="total-header">&nbsp;</div>
      </div>
      
      <div class="hedge-rows">
        <dx-scroll-view>
          <div *ngFor="let positionTransaction of ctx.transaction.positionTransactions" class="positions-row">
            
            <div class="group before">
              
              <div class="group-header" [style.color]="positionTransaction.beforeState?.color">
                {{ positionTransaction.beforeState?.label }}
              </div>
              
              <div class="group-body">
                
                <div class="table-header" [style.color]="positionTransaction.beforeState?.color">
                  <div *ngIf="positionTransaction.beforeState?.positions" class="table-row">
                    <div class="col header qty">Qty</div>
                    <div class="col header asset">Asset</div>
                    <div class="col header type">Type</div>
                    <div class="col header expiration">Expiration</div>
                    <div class="col header strike">Strike</div>
                    <div class="col header price">Price</div>
                  </div>
                </div>
                
                <div class="table-body" [style.color]="positionTransaction.beforeState?.color">
                  <div *ngFor="let hedgePosition of positionTransaction.beforeState?.positions" class="table-row">
                    <div class="col qty">{{ hedgePosition.qty * this.invertMultiplier }}</div>
                    <div class="col asset">{{ hedgePosition.asset }}</div>
                    <div class="col type">{{ hedgePosition.type }}</div>
                    <div class="col expiration">{{ hedgePosition.expiration | etsFriendlyDatePipe }}</div>
                    <div class="col strike">{{ hedgePosition.strike }}</div>
<!--                    (positionTransaction.beforeState.zonesGridStrikeDelta || 0)-->
                    <div [ngClass]="this.getPriceClass(hedgePosition.price)" class="col price">
                      {{ (hedgePosition.price * this.invertMultiplier) | currency }}
                    </div>
                  </div>
                </div>
              
              </div>
              
              <div class="group-footer">
                <div class="table-row">
                  <div class="col footer label">&nbsp;</div>
                  <div [ngClass]="this.getPriceClass(this.getGroupSubTotal(positionTransaction.beforeState))"
                       class="col footer price">
                    {{ this.getGroupSubTotal(positionTransaction.beforeState) | currency }}
                  </div>
                </div>
              </div>
            </div>
            
            <div class="separator">&nbsp;</div>
            
            <div class="group after">
              
              <div class="group-header" [style.color]="positionTransaction.afterState?.color">
                {{ positionTransaction.afterState?.label }}
              </div>
              
              <div class="group-body">
                
                <div *ngIf="positionTransaction.afterState?.positions" class="table-header">
                  <div class="table-row" [style.color]="positionTransaction.afterState?.color">
                    <div class="col header qty">Qty</div>
                    <div class="col header asset">Asset</div>
                    <div class="col header type">Type</div>
                    <div class="col header expiration">Expiration</div>
                    <div class="col header strike">Strike</div>
                    <div class="col header price">Price</div>
                  </div>
                </div>
                
                <div class="table-body" [style.color]="positionTransaction.afterState?.color">
                  <div *ngFor="let hedgePosition of positionTransaction.afterState?.positions" class="table-row">
                    <div class="col qty">{{ hedgePosition.qty * this.invertMultiplier }}</div>
                    <div class="col asset">{{ hedgePosition.asset }}</div>
                    <div class="col type">{{ hedgePosition.type }}</div>
                    <div class="col expiration">{{ hedgePosition.expiration | etsFriendlyDatePipe }}</div>
                    <div class="col strike">{{ hedgePosition.strike }}</div>
                    <div [ngClass]="this.getPriceClass(hedgePosition.price * this.invertMultiplier)" class="col price">
                      {{ hedgePosition.price * this.invertMultiplier | currency }}
                    </div>
                  </div>
                </div>
              
              </div>
              
              <div class="group-footer">
                <div class="table-row">
                  <div class="col footer label">&nbsp;</div>
                  <div [ngClass]="this.getPriceClass(this.getGroupSubTotal(positionTransaction.afterState) * this.invertMultiplier)"
                       class="col footer price">
                    {{ this.getGroupSubTotal(positionTransaction.afterState) * this.invertMultiplier | currency }}
                  </div>
                </div>
              </div>
            
            </div>
            
            <div class="separator">&nbsp;</div>
            
            <div [ngClass]="this.getPriceClass(this.getRowTotal(positionTransaction) * this.invertMultiplier )" class="total">
              {{ this.getRowTotal(positionTransaction) * this.invertMultiplier | currency }}
            </div>
          
          </div>
        </dx-scroll-view>
      </div>
      
      <div class="grand-total-row">
        <div class="col filler">GRAND TOTAL</div>
        <div [ngClass]="this.getPriceClass(this.getGrandTotal(ctx.transaction) * this.invertMultiplier)" class="col grand-total-price">
          {{ this.getGrandTotal(ctx.transaction) * this.invertMultiplier  | currency }}
        </div>
      </div>
    
    </div>
  </div>
  
  <div class="section orders">
    
    <div class="section-header">
      <div class="row">
        <hr>
      </div>
      <div class="header-text">
        <div class="text">
          ORDERS
        </div>
      </div>
      <div class="row">
        <hr>
      </div>
    </div>
    
    <div class="section-body">
      
      <div class="order-group-rows">
        <dx-scroll-view>
          <div *ngFor="let orderGroup of ctx.transaction.orderTransactions" class="order-group-row">
            
            <div class="group-header" [style.color]="orderGroup?.color">
              {{ orderGroup.label }}
            </div>
            
            <div class="group-body">
              <div class="table-header">
                <div class="table-row" [style.color]="orderGroup?.color">
                  <div class="col header action">Action</div>
                  <div class="col header qty">Qty</div>
                  <div class="col header asset">Asset</div>
                  <div class="col header type">Type</div>
                  <div class="col header expiration">Expiration</div>
                  <div class="col header strike">Strike</div>
                  <div class="col header price">Price</div>
                </div>
              </div>
              <div class="table-body" [style.color]="orderGroup?.color">
                <div *ngFor="let orderPkg of orderGroup.balancedOrders" class="table-row-group">
                  <div *ngFor="let order of orderPkg" class="table-row">
                    <div class="col action">{{ this.getOrderActionText(order.action) }}</div>
                    <div class="col qty">{{ order.qty * this.invertMultiplier }}</div>
                    <div class="col asset">{{ order.asset }}</div>
                    <div class="col type">{{ order.type }}</div>
                    <div class="col expiration">{{ order.expiration | etsFriendlyDatePipe }}</div>
                    <div class="col strike">{{ order.strike }}</div>
                    <div [ngClass]="this.getPriceClass(order.price * this.invertMultiplier)" class="col price">
                      {{ order.price * this.invertMultiplier | currency }}
                    </div>
                  </div>
                  
                  <div *ngIf="orderGroup?.balancedOrders?.length > 1" class="table-row">
                    <div class="col header action">&nbsp;</div>
                    <div class="col header qty">&nbsp;</div>
                    <div class="col header asset">&nbsp;</div>
                    <div class="col header type">&nbsp;</div>
                    <div class="col header expiration">&nbsp;</div>
                    <div class="col header strike">&nbsp;</div>
                    <div [ngClass]="this.getPriceClass(this.getOrderPackageTotal(orderPkg) * this.invertMultiplier)" class="col price sub-total">
                      {{ this.getOrderPackageTotal(orderPkg) * this.invertMultiplier | currency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="group-footer">
              <div *ngIf="true" class="table-row">
                <div class="col header action">&nbsp;</div>
                <div class="col header qty">&nbsp;</div>
                <div class="col header asset">&nbsp;</div>
                <div class="col header type">&nbsp;</div>
                <div class="col header expiration">&nbsp;</div>
                <div class="col header strike">&nbsp;</div>
                <div [ngClass]="this.getPriceClass(this.getOrderGroupTotal(orderGroup) * this.invertMultiplier)" class="col price grand-total-price">
                  {{ this.getOrderGroupTotal(orderGroup) * this.invertMultiplier | currency }}
                </div>
              </div>
              <br>
            </div>
          
          </div>
        </dx-scroll-view>
      </div>
    </div>
  
  </div>
  
  <div class="control-buttons" *ngIf="this.context !== 'pkg-cmprsn'">
    <div class="section-header">
      <div class="row">
        <hr>
      </div>
    </div>
    <div class="section-body">
      
    <div class="order-copy">
      <div class="block destination">
        <div class="label">Destination</div>
        <div class="input">
          <dx-select-box
            [items]="this.destinations"
            [value]="this.getDestination(ctx.side)"
            (onValueChanged)="this.onDestinationSelected($event, ctx.side)"
          ></dx-select-box>
        </div>
      </div>
      <div class="block order-to-copy">
        <div class="label">Order To Copy</div>
        <div class="input">
          <dx-select-box
            [items]="this.getOrdersToCopyList(ctx.side)"
            [value]="this.getOrderToCopy(ctx.side)"
            (onValueChanged)="this.onOrderToCopySelected($event, ctx.side)"
          >
          </dx-select-box>
        </div>
      </div>
      <div class="block copy-btn">
        <dx-button text="Copy" type="default" width="100%" (onClick)="this.onCopyClicked(ctx.side)">
        </dx-button>
      </div>
    </div>
    
    </div>
  </div>
  
</ng-template>