<div #root class="root-container">
  
  <div class="body">
    
    <div [ngClass]="{'collapsed': this.positionsSectionCollapsed}" class="column positions">
      
      <ets-hg-section-header
        (toggleCollapsed)="this.toggleSectionCollapsed('positions')"
        [collapsed]="this.positionsSectionCollapsed"
        [text]="'Portfolios'"
      ></ets-hg-section-header>
      
      <div [class.ets-hidden]="this.positionsSectionCollapsed" class="column-body">
        <ets-hg-positions-section
          (portfolioSelected)="this.onPortfolioSelected($event)">
        </ets-hg-positions-section>
      </div>
    
    </div>
    
    <div [ngClass]="{'collapsed': this.settingsSectionCollapsed}" class="column settings">
      
      <ets-hg-section-header
        (toggleCollapsed)="this.toggleSectionCollapsed('settings')"
        (expandHedgePadRequest)="this.onExpandHedgePadRequest()"
        [collapsed]="this.settingsSectionCollapsed"
        [text]="'Hedge Pad'"
        [showHedgePadIcons]="this.showHedgePadIconsInHeader"
      ></ets-hg-section-header>
      
      <div [class.ets-hidden]="this.settingsSectionCollapsed" class="column-body">
        <ets-hg-settings-section
          (reviewChangesClicked)="this.onReviewChangesClicked()"
          [portfolioDefaultQty]="this.portfolioDefaultQty"
        ></ets-hg-settings-section>
      </div>
    
    </div>
    
    <div [ngClass]="{'collapsed': this.strategiesSectionCollapsed}" class="column strategies">
      
      <ets-hg-section-header
        (toggleCollapsed)="this.toggleSectionCollapsed('strategies')"
        [collapsed]="this.strategiesSectionCollapsed"
        [text]="'Strategies'"
      ></ets-hg-section-header>
      
      <div [class.ets-hidden]="this.strategiesSectionCollapsed">
        <ets-hg-strategies-section [portfolioDefaultQty]="this.portfolioDefaultQty">
        </ets-hg-strategies-section>
      </div>
    </div>
    
    <div [ngClass]="{'collapsed': this.priceboxSectionCollapsed}" class="column pricebox">
      
      <ets-hg-section-header
        (toggleCollapsed)="this.toggleSectionCollapsed('pricebox')"
        [collapsed]="this.priceboxSectionCollapsed"
        [text]="'Pricebox'"
      ></ets-hg-section-header>
      
      <div [class.ets-hidden]="this.priceboxSectionCollapsed">
        <ets-hg-pricebox-section [portfolioDefaultQty]="this.portfolioDefaultQty">
        </ets-hg-pricebox-section>
      </div>
    
    </div>
    
    <div [ngClass]="{'collapsed': this.zonesGridSectionCollapsed}" class="column zones-grid">
      
      <ets-hg-section-header
        (toggleCollapsed)="this.toggleSectionCollapsed('zones-grd')"
        (linkStateChanged)="this.onZonesGridLinkStateChanged($event)"
        (clearDataRequest)="this.onZonesGridClearDataRequest()"
        (loadDataRequest)="this.onZonesGridLoadDataClicked()"
        [showZonesGridIcons]="true"
        [collapsed]="this.zonesGridSectionCollapsed"
        [text]="'Zones Grid'"
        [ctx]="this"
      ></ets-hg-section-header>
      
      <div [class.ets-hidden]="this.zonesGridSectionCollapsed" class="zones-grid-body">
        <ets-hg-zones-grid-section>
        </ets-hg-zones-grid-section>
      </div>
    </div>
  </div>
</div>

<dx-load-panel
  [position]="{my: 'center', at:'center', of: root}"
  [visible]="this.isLoading"
></dx-load-panel>
