import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AgGridModule} from 'ag-grid-angular';
import {DateTimePickerModule} from '../datetime-picker/datetime-picker.module';
import {DevExtremeModule} from '../devextreme.module';
import {TimezonePickerModule} from '../timezone-picker/timezone-picker.module';
import {AdjustmentStrategyDialogModule} from '../adjustment-strategy-dialog/adjustment-strategy-dialog.module';
import {TestPanelComponent} from './test-panel.component';
import {AdjustmentPricingGridModule} from '../adjustment-pricing-grid/adjustment-pricing-grid.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ColorPickerModule} from "../color-picker/color-picker.component.module";

@NgModule({
    imports: [
        DevExtremeModule,
        CommonModule,
        AgGridModule.withComponents([]),
        DateTimePickerModule,
        TimezonePickerModule,
        AdjustmentStrategyDialogModule,
        AdjustmentPricingGridModule,
        BrowserAnimationsModule,
        ColorPickerModule
    ],
    exports: [TestPanelComponent],
    declarations: [TestPanelComponent],
    providers: [],
})
export class TestPanelModule {
}
