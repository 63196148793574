<div #chk [ngClass]="{'ets-hidden': !this.isActive}" class="panel-container">

  <div class="main-area">

    <div class="block items-list">

      <div class="buttons">
        <dx-button
          (onClick)="this.addButton()"
          text="Add Button"
          width="100%"
        >
        </dx-button>
      </div>

      <div class="items">
        <hr class="separator">
        <div>
          <dx-list
            [dataSource]="this.resourceButtons"
            [selectionMode]="'single'"
            [(selectedItemKeys)]="this.selectedItemKeys"
            (onSelectionChanged)="this.onChange({})"
            [displayExpr]="'name'"
            [keyExpr]="'id'"
            (onItemReordered)="this.onButtonsReordered($event)"
            (onItemDeleting)="this.onButtonDeleting($event)"
            [itemDragging]="{allowReordering: true}"
            [allowItemDeleting]="true"
            [itemDeleteMode]="'toggle'"
          >
            <div *dxTemplate="let item of 'item'">
              <div class="button-name-container">
                <div [title]="item.title" class="name">
                  <span>{{item.title}}</span>
                </div>
                <div *ngIf="!item.isPublished && !item.hasChanges">&nbsp;&nbsp;&nbsp;[DRAFT]</div>
                <div *ngIf="item.hasChanges">&nbsp;&nbsp;&nbsp;[UNSAVED]</div>

                <div class="draft-toggle">
                  <dx-check-box
                    (valueChange)="this.onButtonDraftStateChange(item, $event)"
                    [disabled]="item.hasChanges"
                    [value]="item.isPublished"
                    class="ets-draft-toggle"
                  >
                  </dx-check-box>
                </div>
              </div>
            </div>
          </dx-list>
        </div>
      </div>

      <div class="save">
        <dx-button
          (onClick)="this.saveAllChanges()"
          [disabled]="!this.hasChanges"
          text="Save Changes"
          width="100%"
        >
        </dx-button>
      </div>

    </div>

    <div class="block editor-area">

      <fieldset class="button-name">
        <legend>Button Attributes</legend>
        <div class="button-name-container">
          <div class="title-input">
            <p class="label">Title</p>
            <dx-text-box
              (onValueChanged)="this.onChange($event)"
              (valueChange)="this.selectedButton ? this.selectedButton.title = $event : null"
              [disabled]="!this.isButtonSelected"
              [value]="this.selectedButton?.title"
            >
            </dx-text-box>
          </div>
          <div class="button-type">
            <p class="label">Type</p>
            <dx-select-box
              (onValueChanged)="this.onChange($event)"
              (valueChange)="this.selectedButton ? this.selectedButton.type = $event : null"
              [disabled]="!this.isButtonSelected"
              [items]="this.buttonTypesList"
              [value]="this.selectedButton?.type"
            >
            </dx-select-box>
          </div>
          <div class="attention">
            <p class="label">Attention</p>
            <div class="input">
              <dx-switch
                [value]="this.selectedButton?.attention || false"
                (valueChange)="this.selectedButton ? this.selectedButton.attention = $event : null"
                (onValueChanged)="this.onAttentionChanged(this.selectedButton, $event)"
                [disabled]="!this.isButtonSelected"
              >
              </dx-switch>
            </div>
          </div>
          <div class="example-button">
            <p class="label">Example</p>
            <dx-button [text]="this.selectedButton?.title"
                       height="30px"
                       width="150px"
            >
            </dx-button>
          </div>
        </div>
      </fieldset>

      <fieldset class="editor-section">

        <legend>Content</legend>

        <div *ngIf="this.selectedButton?.type === 'Link'" class="link-input">
          <dx-text-box
            (onValueChanged)="this.onChange($event)"
            (valueChange)="this.selectedButton
                                 ? this.selectedButton.content = $event
                                 : null"
            [disabled]="!this.isButtonSelected"
            [value]="this.selectedButton?.content"
            placeholder="Please provide a link"
          ></dx-text-box>
        </div>

        <div *ngIf="this.selectedButton?.type === 'Article'" class="editor">
          <dx-html-editor
            (onValueChanged)="this.onChange($event)"
            (valueChange)="this.selectedButton
                                 ? this.selectedButton.content = $event
                                 : null"
            [disabled]="!this.isButtonSelected"
            [value]="this.selectedButton?.content"
            height="100%"
            valueType="html"
          >
            <dxo-toolbar [multiline]="true">
              <dxi-item name="undo"></dxi-item>
              <dxi-item name="redo"></dxi-item>

              <dxi-item name="separator"></dxi-item>

              <dxi-item
                [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
                name="size"
              ></dxi-item>
              <dxi-item
                [acceptedValues]="[
                        'Arial',
                        'Courier New',
                        'Georgia',
                        'Impact',
                        'Lucida Console',
                        'Tahoma',
                        'Times New Roman',
                        'Verdana'
                     ]"
                name="font"
              ></dxi-item>

              <dxi-item name="separator"></dxi-item>

              <dxi-item name="bold"></dxi-item>
              <dxi-item name="italic"></dxi-item>
              <dxi-item name="strike"></dxi-item>
              <dxi-item name="underline"></dxi-item>

              <dxi-item name="separator"></dxi-item>

              <dxi-item name="alignLeft"></dxi-item>
              <dxi-item name="alignCenter"></dxi-item>
              <dxi-item name="alignRight"></dxi-item>
              <dxi-item name="alignJustify"></dxi-item>

              <dxi-item name="separator"></dxi-item>

              <dxi-item name="orderedList"></dxi-item>
              <dxi-item name="bulletList"></dxi-item>

              <dxi-item name="separator"></dxi-item>

              <dxi-item
                [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                name="header"
              ></dxi-item>

              <dxi-item name="separator"></dxi-item>

              <dxi-item name="color"></dxi-item>
              <dxi-item name="background"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="link"></dxi-item>
              <dxi-item name="image"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="clear"></dxi-item>
              <dxi-item name="codeBlock"></dxi-item>
              <dxi-item name="blockquote"></dxi-item>
              <dxi-item name="separator"></dxi-item>
              <dxi-item name="insertTable"></dxi-item>
              <dxi-item name="deleteTable"></dxi-item>
              <dxi-item name="insertRowAbove"></dxi-item>
              <dxi-item name="insertRowBelow"></dxi-item>
              <dxi-item name="deleteRow"></dxi-item>
              <dxi-item name="insertColumnLeft"></dxi-item>
              <dxi-item name="insertColumnRight"></dxi-item>
              <dxi-item name="deleteColumn"></dxi-item>
            </dxo-toolbar>
          </dx-html-editor>
        </div>

      </fieldset>

      <fieldset class="conditions">
        <legend>Conditions</legend>
        <div class="container">
          <div class="condition">
            <ets-asset-condition-resource
              [comp]="this"
              [resource]="this.selectedButton">
            </ets-asset-condition-resource>
          </div>
          <div class="condition">
            <ets-date-condition-resource
              [comp]="this"
              [resource]="this.selectedButton">
            </ets-date-condition-resource>
          </div>
          <div class="condition">
            <ets-day-of-week-condition-resource
              [comp]="this"
              [resource]="this.selectedButton">
            </ets-day-of-week-condition-resource>
          </div>
          <div class="condition">
            <ets-users-condition-resource
              [comp]="this"
              [resource]="this.selectedButton">
            </ets-users-condition-resource>
          </div>
          <div class="condition">
            <ets-extra-po-condition-resource
              [comp]="this"
              [resource]="this.selectedButton">
            </ets-extra-po-condition-resource>
          </div>
        </div>
      </fieldset>
      
    </div>
  </div>

</div>
<dx-load-panel
  [(visible)]="this.isLoading"
  [position]="{my: 'center', at: 'center', of: chk}"
>
</dx-load-panel>
