import {EventEmitter, Injectable} from '@angular/core';
import {ShellClientService} from "./shell-communication/shell-client.service";
import {SessionService} from "./authentication/session-service.service";
import {isVoid} from "./utils";
import {
    DeleteUserSetting,
    ForceGetUserSetting,
    StoreUserSettings2
} from "./shell-communication/shell-operations-protocol";
import {UserSettingDto, UserSettingsChangedDto} from "./shell-communication/shell-dto-protocol";
import {MessageBusService} from "./message-bus.service";
import {isUndefined} from "util";

export interface UserSettingObject {
    userId: string;
    key: string;
    value: any;
}

const KeysForDeferredUpdate = [
    'apg.saved-positions',
    'apg.templates',
    'hg.hedge-positions'
];

@Injectable({providedIn: 'root'})
export class UserSettingsService {

    constructor(
        private readonly _shellClient: ShellClientService,
        private readonly _sessionService: SessionService,
        private readonly _messageBus: MessageBusService,
    ) {
    }

    private readonly _ownState: { [ix: string]: { [ix: string]: any } } = {};
    private readonly _theirCurrentState: { [ix: string]: { [ix: string]: any } } = {};
    private readonly _theirSnapshotState: { [ix: string]: { [ix: string]: any } } = {};

    userSettingChanged$ = new EventEmitter<UserSettingObject>();

    init(settings: UserSettingDto[]): void {
        this.processUserSettings(settings);

        this._messageBus.of<UserSettingsChangedDto>('UserSettingsChangedDto')
            .subscribe(x => this.onUserSettingChanged(x.payload));
    }

    setValue(key: string, value: any, userId?: string): void {

        userId = isVoid(userId) ? this._sessionService.sessionData.userId : userId;

        let userContainer = this._ownState[userId];

        if (!userContainer) {
            userContainer = {};
            this._ownState[userId] = userContainer;
        }

        userContainer[key] = value;

        if (userId !== this._sessionService.sessionData.userId) {
            return;
        }

        this.storeOnServer(key, value, userId);
    }

    getValue<T>(key: string, userId?: string, index?: 'own' | 'their-current' | 'their-snapshot'): T {
        userId = isVoid(userId) ? this._sessionService.sessionData.userId : userId;

        let stateIndex = this._ownState;

        if (index === 'their-snapshot') {
            stateIndex = this._theirSnapshotState;
        } else if (index === 'their-current') {
            stateIndex = this._theirCurrentState;
        }

        let userContainer = stateIndex[userId];

        if (!userContainer) {
            return null;
        }

        let value = userContainer[key];

        if (isUndefined(value)) {
            value = null;
        }

        return value as any;
    }

    async forceGetValue<T>(key: string, userId?: string): Promise<T> {
        let userContainer = this._ownState[userId];
        if (!userContainer) {
            return null;
        }
        const qry = new ForceGetUserSetting(
            userId,
            [key]
        );
        const data = await this._shellClient
            .processQuery<UserSettingDto[]>(qry);

        if (isVoid(data)) {
            return null;
        }

        const value = data[0]?.value || null;

        const parsed = JSON.parse(value);

        return parsed;
    }

    deleteValue(key: string) {
        const userId = this._sessionService.sessionData.userId;
        const container = this._ownState[userId];
        if (!isVoid(container)) {
            delete container[key];
        }
        const cmd = new DeleteUserSetting([key]);
        this._shellClient.processCommand(cmd).catch((e) => {
            console.error(`Failed to delete user setting: ${key}`, e);
        })
    }

    getItemsMatchingPattern<T>(predicate: (string) => boolean, userId: string): { key: string, value: T }[] {

        const container = this._ownState[userId];

        if (isVoid(container)) {
            return [];
        }

        const matching = Object.keys(container)
            .filter(predicate)
            .map(key => {
                return {
                    key: key,
                    value: container[key]
                }
            });

        return matching;
    }

    applyTheirCurrentState(key: string, userId: string) {

        const userContainer = this._theirCurrentState[userId];

        if (isVoid(userContainer)) {
            throw new Error('Deferred user container not found');
        }

        const userContainer1 = this._theirSnapshotState[userId];

        if (isVoid(userContainer1)) {
            throw new Error('Current user container not found');
        }

        const value = userContainer[key];

        userContainer1[key] = value;

        this.resetToSnapshotState(key ,userId);
    }

    resetToSnapshotState(key: string, userId: string) {
        const snapshotContainer = this._theirSnapshotState[userId];

        if (isVoid(snapshotContainer)) {
            throw new Error('Deferred user container not found');
        }

        const ownContainer = this._ownState[userId];

        if (isVoid(ownContainer)) {
            throw new Error('Current user container not found');
        }

        const snapshotValue = snapshotContainer[key];

        ownContainer[key] = snapshotValue;
    }

    private storeOnServer(key: string, value: any, userId: string) {
        userId = isVoid(userId) ? this._sessionService.sessionData.userId : userId;
        const setting: UserSettingDto = {
            userId: userId,
            key: key,
            value: JSON.stringify(value)
        }
        const data = [setting];
        const cmd = new StoreUserSettings2(data);
        this._shellClient.processCommand(cmd)
            .then(() => {
            })
            .catch((e) => {
                const msg: string = `Error storing user settings for user ${userId}. Key=${key}`;
                console.error(msg);
            });
    }

    private processUserSettings(result: UserSettingDto[]) {

        result.forEach((ust) => {
            let ownContainer = this._ownState[ust.userId];
            if (isVoid(ownContainer)) {
                ownContainer = {};
                this._ownState[ust.userId] = ownContainer;
            }
            const value = JSON.parse(ust.value || null);
            ownContainer[ust.key] = value;

            if (ust.userId === this._sessionService.sessionData.userId) {
                return;
            }

            let theirSnapshotContainer = this._theirSnapshotState[ust.userId];
            if (isVoid(theirSnapshotContainer)) {
                theirSnapshotContainer = {};
                this._theirSnapshotState[ust.userId] = theirSnapshotContainer;
            }
            theirSnapshotContainer[ust.key] = value;

            let theirCurrentContainer = this._theirCurrentState[ust.userId];
            if (isVoid(theirCurrentContainer)) {
                theirCurrentContainer = {};
                this._theirCurrentState[ust.userId] = theirCurrentContainer;
            }
            theirCurrentContainer[ust.key] = value;
        });
    }

    private onUserSettingChanged(payload: UserSettingsChangedDto) {

        payload.settings.forEach(setting => {

            let container = this._theirCurrentState[setting.userId];
            if (isVoid(container)) {
                container = {};
                this._theirCurrentState[setting.userId] = container;
            }

            const value = JSON.parse(setting.value || null);
            container[setting.key] = value;
            try {
             this.userSettingChanged$.emit({userId: setting.userId, key: setting.key, value: value});
            } catch {
                //
            }
        });
    }

    removeKeysMatchingPattern(id: string) {

    }
}