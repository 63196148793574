import {NgModule} from '@angular/core';
import {EtsNgClassAsyncPipe} from "./ets-ngclass-async.pipe";
import {IsValidNumberPipe} from "./is-valid-number.pipe";
import {PriceFormatterPipe} from "./price-formatter.pipe";

@NgModule({
    imports: [],
    exports: [
        EtsNgClassAsyncPipe,
        IsValidNumberPipe,
        PriceFormatterPipe
    ],
    declarations: [
        EtsNgClassAsyncPipe,
        IsValidNumberPipe,
        PriceFormatterPipe
    ],
    providers: [],
})
export class EtsCommonModule {
}
