import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { LayoutTabsComponent } from './layout-tabs/layout-tabs.component';
import { WorkspaceModule } from '../workspace/workspace.module';
import { LayoutsSortComponent } from './layout-sort/layouts-sort.component';
import { AngularSplitModule } from 'angular-split';
import { DevExtremeModule } from '../devextreme.module';
import { WebtraderHostMenuModule } from 'projects/webtrader/src/app/host-menu/host-menu.module';
import {ResourceEditorModule} from "../resource-editor/resource-editor.module";

@NgModule({
   declarations: [
      LayoutComponent,
      LayoutTabsComponent,
      LayoutsSortComponent
   ],
    imports: [
        CommonModule,
        AngularSplitModule,
        WorkspaceModule,
        DevExtremeModule,
        WebtraderHostMenuModule,
        ResourceEditorModule
    ],
   exports: [
      LayoutComponent,
      LayoutTabsComponent,
      LayoutsSortComponent
   ],
   providers: [],
})
export class LayoutModule { }
