import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {DetectMethodChanges, isVoid} from "../../utils";
import {MessageBusService} from "../../message-bus.service";
import {AtmStrikeChangedEvent} from "../../common-services/atm-strike-service/atm-strike-changed-event";
import {debounce, debounceTime, delay} from "rxjs/operators";
import {Subscription} from "rxjs";
import {AccessControlService} from "../../access-control-service.class";

@Component({
    selector: 'ets-hg-section-header',
    templateUrl: 'hg-section-header.component.html',
    styleUrls: [
        './hg-section-header.component.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgSectionHeaderComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _messageBus: MessageBusService,
        private readonly _accessControl: AccessControlService
    ) {
    }

    private _subscriptions: Subscription[] = [];

    isLinked: boolean;

    @Input() ctx: any;

    @Input() text: string;

    @Input() collapsed: boolean;

    @Input() showZonesGridIcons: boolean;

    @Input() showHedgePadIcons: boolean;

    @Output() toggleCollapsed = new EventEmitter();

    @Output() linkStateChanged = new EventEmitter<boolean>();

    @Output() clearDataRequest = new EventEmitter();

    @Output() loadDataRequest = new EventEmitter();

    @Output() expandHedgePadRequest = new EventEmitter();

    get zonesGridParametersAvailable(): boolean {
        return this._accessControl
            .isSecureElementAvailable('71e61c26-dcc2-49da-9269-61705d7b423c');
    }


    ngOnInit() {
        this._subscriptions.push(
            this._messageBus.of('Hg.AfterReviewChanges')
                .subscribe(x => this._changeDetector.detectChanges())
        );

        this._subscriptions.push(
            this._messageBus.of<AtmStrikeChangedEvent>('AtmStrikeChangedEvent')
                .pipe(debounceTime(1000))
                .subscribe(x => this.onAtmStrikeChanged(x.payload))
        );

        this._subscriptions.push(
            this._messageBus.of('Hg.HedgesCleared')
                .pipe(
                    delay(250) // hack to let <ets-hg-zones-grid-section> component subscribe before this header
                )
                .subscribe(x => this._changeDetector.detectChanges())
        );

        this._subscriptions.push(
            this._messageBus.of('Hg.PortfolioSelected')
                .subscribe(x => this._changeDetector.detectChanges())
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    onToggleCollapsed() {
        this.toggleCollapsed.emit();
    }

    @DetectMethodChanges()
    onLinkStateChange() {
        this.isLinked = !this.isLinked;
        this.linkStateChanged.emit(this.isLinked);
    }

    onClearDataClicked() {
        this.clearDataRequest.emit()
    }

    onLoadDataClicked() {
        this.loadDataRequest.emit();
    }

    onExpandClicked() {
        this.expandHedgePadRequest.emit();
    }

    hasDifference(): boolean {
        if (isVoid(this.ctx)) {
            return false;
        }

        return this.ctx.isZonesGridDifferent();
    }

    isOutdated() {
        if (isVoid(this.ctx)) {
            return false;
        }
        return this.ctx.isZonesGridOutdated();
    }

    private onAtmStrikeChanged(payload: AtmStrikeChangedEvent) {

        this._changeDetector.detectChanges();
    }

    canShowZonesGridDownloadIcon() {
        return this.showZonesGridIcons && this.ctx.canLoadZonesGrid();
    }

    onShowZonesGridMenuClicked() {
        this.ctx.showZonesGridMenu();
    }
}